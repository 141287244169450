/// Buttons
/// @group Breakpoints
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.btn,
button {
    outline: none;
    border: none;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background: none;
    appearance: none;
    @include text--s;
    color: inherit;
    cursor: pointer;

    &.btn--outline {
        border: 1px dashed $border--default;
        border-radius: 2px;
        background: transparent;
        white-space: nowrap;
        padding: 0.2rem 0.5rem 0;
        width: auto;
        display: inline-block;
    }

    &.btn--icon {
        display: flex;
        align-items: center;

        > span:first-child {
            margin-right: 1rem;
            line-height: 0;
        }

        > span:last-child {
            margin-top: 0.2rem; // center text vertically

            .ios & {
                margin-top: 0.4rem;
            }
        }
    }

    &.btn--cta {
        border: 1px solid $border--default;
        border-radius: 2px;
        padding: 1rem 2rem;
        display: inline-block;
        color: $text--highlight;
        @include text--italic;
        margin-top: 2rem;
    }
}

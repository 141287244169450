/// Slideshows for product and news
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

// General slideshow definition
// .slideshow--big as default
.slideshow {
    width: 100%;
    position: relative;
    transition: opacity $trans-time--l $trans-func--default;
    opacity: 1;
    margin-top: $v-space--l * -1; // .slideshow__slide needs some overflow offset which needs to be neutralized here

    &.slideshow:not(.flickity-enabled) {
        white-space: nowrap; // Keep elements in one line // flex solution would lead to different heights
        overflow: hidden;

        > * {
            display: inline-block;
            float: none;
            white-space: normal;
            opacity: 0;

            // show first child anyways
            &:first-child {
                opacity: 1;
            }
        }

        @include respond-to(decent) {
            &.slideshow--small {
                > * {
                    position: relative;

                    .slideshow__slide-header {
                        position: absolute;
                        top: $v-space--l;
                        right: 0;
                    }

                    // show secind child ans well
                    &:nth-child(2) {
                        opacity: 1;
                    }
                }
            }
        }

        @include respond-to(grid-m) {
            &.slideshow--small {
                > * {
                    .slideshow__slide-header {
                        position: relative;
                        top: auto;
                        right: auto;
                    }
                }
            }
        }
    }

    // Handle display and enabling of slideshows
    // Enable Flickity by default
    &:after {
        content: 'flickity';

        // Add clearfix as well
        display: table;
        visibility: hidden;
        height: 0;
        line-height: 0;
        font-size: 0;
        overflow: hidden;
        clear: both;
    }

    // Include full clearfix
    &:before {
        content: ' ';
        display: table;
    }

    &.flickity-enabled.is-draggable {
        .media-box {
            cursor: grab;
        }
    }

    &.flickity-enabled.is-draggable .is-pointer-down {
        .media-box {
            cursor: grabbing;
        }
    }

    &.slideshow--small {
        padding: 0 body-padding();
        overflow: hidden;

        .flickity-viewport {
            overflow: visible;
        }
    }

    @include respond-to(decent) {
        margin-top: $v-space--xxl;

        .is-active-cover--still & {
            z-index: map-get($z-index, active-trigger);
        }

        &.slideshow--small {
            margin-top: $v-space--m;
            padding: 0 main-col-padding(decent, true) $v-space--xxl;
        }
    }

    @include respond-to(grid-m) {
        &.slideshow--small {
            padding: 0 main-col-padding(grid-m, true) $v-space--xxl;
        }
    }
}

.slideshow__slide {
    width: 100vw;
    padding: $v-space--l body-padding() $v-space--xl;

    // stack z-index correctly
    @for $i from 1 through 30 {
        &:nth-child(#{$i}) {
            z-index: 31 - $i;
        }
    }

    // news slider
    .slideshow--small & {
        width: grid-width(5);
        display: flex;
        flex-direction: column-reverse;
        padding: $v-space--l 0 $v-space--xl;
    }

    // force image to bottom of slider
    .slideshow--small.flickity-enabled & {
        min-height: 100%;
    }

    @include respond-to(decent) {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        padding: 0 main-col-padding(decent, true);

        // news slider
        .slideshow--small & {
            width: grid-width(5, decent);
            flex-direction: row;
        }
    }

    @include respond-to(grid-m) {
        padding: 0 main-col-padding(grid-m, true);

        // news slider
        .slideshow--small & {
            display: block;
            padding-right: $v-space--xxl;
        }
    }
}

.slideshow__slide-img {
    width: grid-width(5); // 5 of 6 cols of inner width
    height: grid-width(5);

    // Parallax effect
    @supports(--css: variable) {
        --img-offset: 0;
        --img-scale: 1;
        transform: translateX(var(--img-offset)) scale(var(--img-scale));
        transform-origin: 50% 50%;

        // Predefine initial state for second image
        .slideshow__slide:nth-child(2) & {
            --img-offset: -40vw;
            --img-scale: 0.875;
        }
    }

    .media-box {
        height: 100%;
    }

    .media-box__title {
        color: inherit;
        text-align: right;
        transform: none;
        top: auto;
        bottom: 0;
        padding: 0.6rem 1rem;

        h5 {
            @include text--xs;
        }
    }

    // Include shadow
    &:before {
        @include pseudo();
        top: 3rem;
        left: 3rem;
        right: 0;
        bottom: 3rem;
        box-shadow: 0 0 5rem rgba(0, 0, 0, 0.15);
    }

    // Parallax effect news slider
    @supports(--css: variable) {
        // Predefine initial state for second image
        .slideshow--small .slideshow__slide:nth-child(2) & {
            --img-offset: -40vw;
            --img-scale: 1;
        }
    }

    // news slider
    .slideshow--small & {
        width: grid-width(5);
        height: grid-width(5.5);

        // no shadow
        &:before {
            display: none;
        }
    }

    @include respond-to(decent) {
        width: 50%;
        height: auto;
        z-index: 2; // show before cover
        transition: opacity $trans-time--nav $trans-func--nav;

        // Parallax effect
        @supports(--css: variable) {
            // Predefine initial state for second image
            .slideshow__slide:nth-child(2) & {
                --img-offset: -74vw;
                --img-scale: 0.8;
            }
        }

        .media-box {
            height: 0;
            padding-bottom: 100%;
            transition: transform $trans-time--nav $trans-func--nav;
            transform-origin: center center;
        }

        .media-box__title {
            text-align: left;
            padding: 2.4rem 3rem;
            opacity: 0.5;
            transition: opacity $trans-time--l $trans-func--default;

            .is-selected & {
                opacity: 1;
            }

            h5 {
                @include text--xs;
            }
        }

        // handle modal active state
        .is-active-trigger & {
            transform:  translate3d(calc(#{main-col-padding(decent, false, true)} - ((50vw - #{body-padding(decent)}) * 0.1)), 0 , 0); // move to correct position, then compensate for scaling

            &:before,
            .media-box {
                transform: scale(0.8);
            }

            .media-box__title {
                transition: opacity $trans-time--nav $trans-func--default;
                opacity: 0;
            }
        }

        .is-active-trigger--still & {
            transition: transform $trans-time--nav $trans-func--nav,
            opacity $trans-time--nav $trans-func--nav;
        }

        .is-active-trigger + .slideshow__slide & {
            opacity: 0.12;
        }

        // Hide shadow for unactive
        &:before {
            opacity: 0;
            transform-origin: center center;
            transition: opacity $trans-time--l $trans-func--default,
                        transform $trans-time--nav $trans-func--nav;

            .is-selected:not(:last-child) & {
                opacity: 1;
            }
        }

        // news slider
        .slideshow--small & {
            padding-right: 1.5rem;
            width: 50%;
            height: auto;
            transform: none; // no transform here

            .media-box {
                height: 0;
                padding-bottom: 120%;
            }
        }
    }

    @include respond-to(grid-m) {

        // handle modal active state
        .is-active-trigger & {
            transform:  translate3d(calc(#{main-col-padding(grid-m, false, true)} - ((50vw - #{body-padding(grid-m)}) * 0.1)), 0 , 0); // move to correct position, then compensate for scaling
        }

        // news slider
        .slideshow--small & {
            width: 100%;
            padding-right: 0;
        }
    }
}

.slideshow__slide-header {
    padding-top: $v-space--m;

    h2,
    h3 {
        @include text--xl;
    }

    // news slider
    .slideshow--small & {
        padding-top: $v-space--s;
        padding-bottom: $v-space--m;
        padding-right: 3rem;
        flex: 1 1 auto;

        h2,
        h3 {
            @include text--m;
        }

        .text-link {
            display: none;
        }
    }

    @include respond-to(decent) {
        width: 50%;
        padding: $v-space--xl main-col-padding(decent) 0 0;

        .slideshow--small & {
            padding: $v-space--m 5rem $v-space--s 1.5rem;
            flex: 0 0 auto;
            position: relative;
            overflow: hidden;

            .text-link {
                display: block;
            }

            &:before {
                @include pseudo();
                top: 0;
                right: 3rem;
                width: 2rem;
                height: 2rem;
                border-top: 1px solid $border--default;
                border-right: 1px solid $border--default;
            }
        }
    }

    @include respond-to(grid-m) {
        padding: $v-space--xl main-col-padding(grid-m) 0 0;

        .slideshow--small & {
            width: 100%;
            padding: $v-space--s 0 0 0;

            &:before {
                display: none;
            }
        }
    }
}

/// Main hero or pagetitle
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////


// simple header
.simple-hero {
    padding: body-padding();
    border-top: 1px solid $border--default;
    background: $background--default;
    position: relative;

    h1,
    h2,
    h3 {
        @include text--xl;
    }

    // modal styles
    .modal & {
        border-top: none;
        padding-right: 7em; // prevent crashing with close icon

        @supports (position: sticky) {
            position: sticky;
            top: 0;
            z-index: 1;
        }

        h1,
        h2,
        h3 {
            @include text--l;
        }
    }

    @include respond-to(grid-s) {
        // simple header without intro on mobile
        .header-intro.hidden-on-mobile + main & {
            min-height: calc(#{body-padding() * 2} + 5rem + #{body-padding()} + 10rem); // top and bottom padding plus some
            padding: calc(#{body-padding() * 2} + 5rem) body-padding() body-padding(); // prevent text being behind main header
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            border-top: none;
        }
    }

    @include respond-to(decent) {
        border-top: none;
        padding: couple-top-padding() body-padding(decent) $v-space--m 50vw;

        .modal & {
            padding: header-height(decent) main-col-padding(decent) $v-space--m body-padding(decent);
        }

        .has-modal--rtl .modal & {
            padding: header-height(decent) body-padding(decent) $v-space--m main-col-padding(decent);
        }
    }

    @include respond-to(grid-m) {
        padding: couple-top-padding(grid-m) body-padding(grid-m) $v-space--m 50vw;

        .modal & {
            padding: header-height(grid-m) main-col-padding(grid-m) $v-space--m body-padding(grid-m);
        }

        .has-modal--rtl .modal & {
            padding: header-height(grid-m) body-padding(grid-m) $v-space--m main-col-padding(grid-m);
        }
    }
}


.simple-hero--l {
    @include respond-to(decent) {
        min-height: 34rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: $v-space--l;
    }
}

/// Secondary page headerwith claim and mobile pagetitle
/// @group Header
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.header-intro {
    min-height: calc(#{body-padding() * 2} + 5rem + #{body-padding()} + 10rem); // top and bottom padding plus some
    padding: calc(#{body-padding() * 2} + 5rem) body-padding() body-padding(); // prevent text being behind main header
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include respond-to(decent) {
        padding: body-padding(decent) body-padding(decent) body-padding(decent) 50vw;
        height: 10rem;
        min-height: 0;
        margin-bottom: -10rem;
        flex-direction: row;
        justify-content: center;
    }

    @include respond-to(grid-m) {
        padding: body-padding(grid-m) body-padding(grid-m) body-padding(grid-m) 50vw;
    }
}

.header-intro__claim {
    h2 {
        @include text--s;
        max-width: 25rem;
    }

    @include respond-to(grid-s) {
        body:not(.page--homepage) & {
            display: none;
        }
    }

    @include respond-to(decent) {
        width: $main-col-width;
        z-index: 1; // show on top of couples
        position: relative;
    }

    @include respond-to(grid-m) {
        width: $main-col-width--m;
    }
}

.header-intro__page-title {
    h1 {
        @include text--xl;
    }
}

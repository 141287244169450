/// Main Navigation
/// @group nav
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

body {
    &.has-menu {
        height: 100%;
        overflow: hidden;
    }

    @include respond-to(grid-l) {
        overflow: visible;
        height: auto;

        &.has-secondary-menu {
            overflow: hidden;
        }
    }
}

.nav-main {
    position: relative;
    padding: body-padding();
    width: 50%; // have logo behind still be clickable

    @include respond-to(decent) {
        padding: 0;
    }

    @include respond-to(grid-m) {
        padding: body-padding(grid-m);
    }
}

.nav-main__navicon {
    position: relative;

    .svg-ico--gol-close {
        display: none;
    }

    .has-menu & {
        .svg-ico--gol-close {
            display: block;
        }

        .svg-ico--gol-navicon {
            display: none;
        }
    }
}

.nav-main__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: $background--default;
    padding: body-padding();
    min-height: 72vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transition: transform $trans-time--nav $trans-func--nav,
                opacity $trans-time--nav $trans-func--nav;

    body:not(.has-menu) & {
        transition: transform $trans-time--nav $trans-func--nav,
                    opacity $trans-time--nav $trans-func--nav,
                    visibility 0s linear $trans-time--nav;
        transform: translate3d(0, -8rem, 0);
        opacity: 0;
        visibility: hidden;
    }

    a,
    .btn[data-action="nav:toggleSecondary"] {
        @include text--s;
        display: block;
    }

    @include respond-to(grid-l) {
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        background: transparent;
        min-height: 1px;
        overflow: visible;
        padding: body-padding(decent);

        body:not(.has-menu) & {
            transform: translate3d(0, 0, 0); // use 0, 0, 0 to prevent disappear on Safari
            opacity: 1;
            visibility: visible;
        }
    }

    @include respond-to(grid-m) {
        height: 70rem;
        max-height: 90vh !important;
        padding: body-padding(grid-m);

        a {
            @include text--m;
        }
    }
}

.nav-main__primary-links {
    margin-top: 7rem;
    position: relative;
    height: 100%;
    padding-bottom: 8rem; // prevent contact info going over
    flex: 1 1 auto;

    > li {
        width: 50%;
        line-height: $line-height--s;
        padding-right: 2rem;
        transition: transform $trans-time--nav-inner $trans-func--nav,
                    opacity $trans-time--nav-inner $trans-func--nav;

        @include transition-delay(0.05s, 0s, 8);
        @include underline-active;

        body:not(.has-menu) & {
            transition: none;
            transform: translate3d(0, 8rem, 0);
            opacity: 0;
        }

        > a,
        > .btn[data-action="nav:toggleSecondary"] {
            @include text--m;
        }

        // nav gap
        &:nth-child(4) {
            margin-top: 1.5rem;
        }
    }

    @include fade-cover(bottom, 4em);

    @include respond-to(grid-l) {
        margin-top: 0;
        position: static;
        height: auto;
        padding-bottom: 0;
        display: flex;

        > li {
            width: auto;
            line-height: $line-height--s;
            padding-right: 1rem;
            z-index: 2;

            &:last-child {
                z-index: 1;
            }

            body:not(.has-menu) & {
                transform: none;
                opacity: 1;
            }

            > a,
            > .btn[data-action="nav:toggleSecondary"] {
                @include text--s;
                position: relative;
            }

            // nav gap
            &:nth-child(4) {
                margin-top: 0;
                margin-left: 2rem;
            }
        }

        &:after {
            display: none;
        }
    }

    @include respond-to(grid-m) {
        > li {
            > a,
            > .btn[data-action="nav:toggleSecondary"] {
                @include text--l;
            }
        }
    }
}

.nav-main__secondary-links {
    position: absolute;
    top: 0;
    left: 50%;
    max-height: 100%;
    overflow-y: auto;
    width: calc(50vw - #{body-padding()});

    body:not(.has-secondary-menu) & {
        visibility: hidden;
        transition: visibility 0s linear $trans-time--s;
    }

    li {
        padding: 0.175rem 0;
        transition: transform 0s linear $trans-time--s,
                    visibility 0s linear $trans-time--s,
                    opacity $trans-time--s $trans-func--nav;
        transform: translate3d(0, 4rem, 0);
        opacity: 0;
        @include underline-active;

        .has-secondary-menu & {
            transition: transform $trans-time--s $trans-func--nav,
                        opacity $trans-time--s $trans-func--nav;
            @include transition-delay(0.03s, 0s, 40);
            transform: none;
            opacity: 1;
        }
    }

    @include respond-to(grid-l) {
        left: 0;
        width: 100vw;
        background: $background--default;
        padding: body-padding(decent) body-padding(decent) $v-space--xl 50vw;
        max-height: 95vw;
        overflow: hidden;
        transition: transform $trans-time--nav $trans-func--nav,
                    opacity $trans-time--nav $trans-func--nav;

        body:not(.has-secondary-menu) & {
            transform: translate3d(0, -8rem, 0);
            opacity: 0;
            transition: transform 0s linear $trans-time--nav,
                        opacity $trans-time--nav $trans-func--nav,
                        visibility 0s linear $trans-time--nav;
        }

        li {
            padding: 0;
            transform: translate3d(0, 4rem, 0);

            .has-secondary-menu & {
                transition: transform $trans-time--nav-inner $trans-func--nav,
                            opacity $trans-time--nav-inner $trans-func--nav;
                @include transition-delay(0.02s, 0.1s);
            }

            a {
                @include text--m;
            }
        }
    }

    @include respond-to(grid-m) {
        width: calc(50vw - #{body-padding(grid-m)});
    }
}

.nav-main__close-secondary {
    position: absolute;
    top: 0;
    right: -50vw;
    font-size: inherit;
    padding: body-padding();

    body:not(.has-secondary-menu) & {
        display: none;
    }

    @include respond-to(decent) {
        padding: body-padding(decent);
    }
}

.nav-main__contact {
    position: absolute;
    bottom: body-padding();
    right: body-padding();
    left: body-padding();
    transition: transform $trans-time--nav-inner $trans-func--nav 0.4s,
                opacity $trans-time--nav-inner $trans-func--nav 0.4s;

    > * {
        @include text--s;
    }

    a {
        display: inline-block;
    }

    @include respond-to(grid-s) {
        body:not(.has-menu) &,
        .has-secondary-menu & {
            transition: transform $trans-time--s $trans-func--nav,
                        opacity $trans-time--s $trans-func--nav,
                        visibility 0s $trans-func--nav $trans-time--s;
            opacity: 0;
            transform: translate3d(0, 1rem, 0);
            visibility: hidden;
        }
    }

    @include respond-to(grid-m) {
        width: calc(50vw - #{body-padding(grid-m)});
        bottom: body-padding(grid-m);
        right: body-padding(grid-m);
        left: body-padding(grid-m);

        > * {
            @include text--m;
        }
    }
}

.nav-main__cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: -40vh; // prevent flickering on addressbar change on ios
    right: 0;
    background: $background--fade;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    transition: opacity $trans-time--nav $trans-func--nav,
                visibility 0s $trans-func--nav $trans-time--nav;

    .has-menu & {
        transition: opacity $trans-time--nav $trans-func--nav;
        opacity: 1;
        visibility: visible;
    }

    @include respond-to(grid-l) {
        .has-menu & {
            opacity: 0;
            visibility: hidden;
        }

        .has-secondary-menu & {
            transition: opacity $trans-time--nav $trans-func--nav;
            opacity: 1;
            visibility: visible;
        }
    }
}

/// Mediabox, Images and Lazyloading
/// @group Mediabox
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.media-box {
    position: relative;
    overflow: hidden;

    img {
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: 50% 50%;
        transition: transform $trans-time--l $trans-func--default,
                    opacity $trans-time--l $trans-func--default;

        .ie & {
            font-family: 'object-fit: cover; object-position: 50% 50%;'; // ie fix probably
        }
    }

    // Hide Lazy images at first
    .lazy {
        opacity: 0;
        transform: scale(1.05);
    }

    // then aninmate in
    .lazy--loaded {
        opacity: 1;
        transform: none;
    }
}

// show grey background if needed
.media-box--bg {
    background: $background--img;

    img {
        mix-blend-mode: multiply;
    }
}

// black background for dimmed images with title on top
.media-box__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    transition: opacity $trans-time--l $trans-func--default;
    opacity: 0;

    .lazy--loaded ~ & {
        opacity: 0.13;
    }
}

// needed for ratio keeping images
.media-box__inner {
    width: 100%;
    position: relative;
}

.media-box__title {
    position: absolute;
    top: 47%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    padding: 2rem;
    color: $text--inverted;
    text-align: center;

    h2,
    h3 {
        @include text--xl;
        @include text--italic;
    }
}

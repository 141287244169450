/// Product modals
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

body {
    &.has-modal {
        height: 100%;
        overflow: hidden;
    }

    &.has-modal,
    &.has-modal--fading {
        // prevent other sections to show above cover
        main > *,
        .footer-main {
            &:not(.is-active-cover--still) {
                z-index: -1;
            }
        }
    }
}

// General modal definition
.modal {
    position: relative;
    z-index: map-get($z-index, modal);
}

.modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    transform-origin: center center;
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
    visibility: hidden;

    // modal coming from right
    .has-modal--rtl & {
        transform: translate3d(20%, 0, 0);
        transition: none; // reset transform without transition
        flex-direction: column-reverse;
    }

    // show modal
    .has-modal & {
        transition: transform $trans-time--nav $trans-func--nav,
                    opacity $trans-time--nav $trans-func--nav;
        transform: none;
        opacity: 1;
        visibility: visible;
    }

    // modal is fading out
    .has-modal--fading & {
        transition: transform $trans-time--nav $trans-func--nav,
                    opacity $trans-time--nav $trans-func--nav,
                    visibility 0s linear $trans-time--nav;
    }

    // Make wrapper scrollable if fully there
    .has-modal--fully-opened & {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    @include respond-to(mobile) {
        // mobile modals always come from bottom
        body:not(.has-modal) & {
            transform: translate3d(0, 50vh, 0);
        }
    }
}

.modal__inner {
    background: $background--default;
    min-height: 100vh;
    position: relative;

    @include respond-to(decent) {
        width: calc(50vw + #{main-col-padding(decent, false, true)});

        .has-modal--rtl & {
            float: right;
        }
    }

    @include respond-to(grid-m) {
        width: calc(50vw + #{main-col-padding(grid-m, false, true)});
    }
}

.modal__cover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $background--fade;
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    transition: opacity $trans-time--nav $trans-func--nav,
                visibility 0s $trans-func--nav $trans-time--nav;

    .has-modal & {
        transition: opacity $trans-time--s $trans-func--nav;
        opacity: 1;
        visibility: visible;
    }

    @include respond-to(decent) {
        .has-modal--btn-triggered & {
            display: none;
        }
    }
}

.modal__close-btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: map-get($z-index, modal);
    cursor: pointer;
    padding: body-padding();
    opacity: 0;
    visibility: hidden;

    .has-modal & {
        transition: opacity $trans-time--l $trans-func--nav $trans-time--nav;
        opacity: 1;
        visibility: visible;
    }

    .svg-ico--gol-close-desktop {
        display: none;
    }

    @include respond-to(decent) {
        right: calc(50vw - #{main-col-padding(decent, false, true)});
        padding: body-padding(decent);

        .svg-ico--gol-close {
            display: none;
        }

        .svg-ico--gol-close-desktop {
            display: block;
        }

        .has-modal--rtl & {
            right: 0;
        }
    }

    @include respond-to(grid-m) {
        right: calc(50vw - #{main-col-padding(grid-m, false, true)});
        padding: body-padding(grid-m);
    }
}

.modal__close {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    display: none;

    @supports (position: sticky) {
        // makes this area scrollable as well
        height: 100vh;
        margin-bottom: -100vh;
        position: sticky;
    }

    .has-modal & {
        display: block;
    }
}


// Performance boost for ipad
.ios {
    @supports (clip-path: inset(0 0 0 0)) {
        @include respond-to(decent) {
            .has-modal--fully-opened {
                .header-main,
                .header-intro,
                main {
                    clip-path: inset(0 0 0 calc(50vw + #{main-col-padding(decent, false, true)}));
                }
            }

            .has-modal--rtl.has-modal--fully-opened {
                .header-main,
                .header-intro,
                main {
                    clip-path: inset(0 calc(50vw + #{main-col-padding(decent, false, true)}) 0 0);
                }
            }
        }

        @include respond-to(grid-m) {
            .has-modal--fully-opened {
                .header-main,
                .header-intro,
                main {
                    clip-path: inset(0 0 0 calc(50vw + #{main-col-padding(grid-m, false, true)}));
                }
            }

            .has-modal--rtl.has-modal--fully-opened {
                .header-main,
                .header-intro,
                main {
                    clip-path: inset(0 calc(50vw + #{main-col-padding(grid-m, false, true)}) 0 0);
                }
            }
        }
    }
}

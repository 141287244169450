/// Main content sections
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////


// content sections
.content-section {
    background: $background--default;
    position: relative;
    border-top: 1px solid $border--default;

    .simple-hero + & {
        body:not(.page--metaPages) & {
            border-top: none;
        }
    }

    .main-col {
        padding: $v-space--m body-padding() $v-space--s;
    }

    > .main-col {
        &:first-child {
            padding-top: $v-space--l;
        }

        &:last-child {
            padding-bottom: $v-space--xl;
        }
    }

    > .listing {
        padding: $v-space--xl body-padding();
    }

    @include respond-to(decent) {
        .simple-hero + & {
            border-top: none;
        }

        .main-col {
            padding: $v-space--m body-padding(decent) $v-space--s 50vw;

            .modal & {
                padding: $v-space--m main-col-padding(decent) $v-space--s body-padding(decent);
            }

            .has-modal--rtl .modal & {
                padding: $v-space--m body-padding(decent) $v-space--s main-col-padding(decent);
            }
        }

        > .main-col {
            &:first-child {
                padding-top: $v-space--xxl;
            }

            &:last-child {
                padding-bottom: $v-space--xxl + $v-space--m;
            }
        }

        > .listing {
            padding: $v-space--xl body-padding(decent) $v-space--xxl;
        }

        > .gallery:first-child {
            padding-top: $v-space--xl;
        }

        > .gallery:last-child {
            padding-bottom: $v-space--xxl + $v-space--l;
        }

        &:not(.content-section--menu-cover) {
            @include clearfix(); // clearfix for floating product image
        }

        // prepare specific modal cover for product modals
        @include modal-cover();

        &.content-section--menu-cover {
            // Add cover for menu
            @include menu-cover();

            // Put header top of content
            .section-head {
                z-index: 1;
            }
        }

    }

    @include respond-to(grid-m) {
        .main-col {
            padding: $v-space--m body-padding(grid-m) $v-space--s 50vw;

            .modal & {
                padding: $v-space--m main-col-padding(grid-m) $v-space--s body-padding(grid-m);
            }

            .has-modal--rtl .modal & {
                padding: $v-space--m body-padding(grid-m) $v-space--s main-col-padding(grid-m);
            }
        }

        > .listing {
            padding: $v-space--xl body-padding(grid-m) $v-space--xxl;
        }
    }
}

.section-head {
    h3,
    h4 {
        @include text--s;
    }

    @include respond-to(decent) {
        position: sticky;
        top: calc(#{body-padding(decent)} - #{$v-space--m});
    }

    @include respond-to(grid-m) {
        top: calc(#{body-padding(grid-m)} - #{$v-space--m});
    }
}

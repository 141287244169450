/// Elements to implement desktop split screen layout
/// @group Couples
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.couple {
    h1,
    h2 {
        @include text--xl;
    }

    // images section
    .couple__img {
        padding: $v-space--l main-col-padding() 0 0;

        // get ready for teaser
        a {
            display: block;
            height: 100%;
            width: 100%;
        }

        .media-box {
            padding-bottom: 110%;
            width: 100%;
            background: rgba(255,255,255,0.25);
        }
    }

    // couple variation with passepartout
    &.couple--passepartout {
        .couple__img {
            padding: $v-space--l main-col-padding();
        }
    }

    // content section
    .couple__content {
        padding: $v-space--m body-padding() $v-space--xxl body-padding();
        background: $background--default;
        position: relative;
    }

    // reversing direction
    &.couple--rtl {
        &:not(.couple--passepartout) {
            .couple__img {
                padding: $v-space--l 0 0 main-col-padding();
            }
        }
    }

    // without image
    &.couple--blank {
        .media-box__title {
            color: $text--default;
        }
    }

    @include respond-to(grid-s) {
        // extandable couples mobile only
        &.couple--extendable {

            // icon
            .couple__img .media-box {
                position: relative;

                &:after {
                    @include pseudo();
                    bottom: 0;
                    right: 0;
                    background-image: svg-inline(icon--gol-extend);
                    background-size: 3rem 3rem;
                    background-position: center center;
                    width: 3rem;
                    height: 3rem;
                    margin: 0.5rem;
                }
            }

            .couple__content {
                padding: $v-space--m body-padding(decent) $v-space--xxl body-padding(decent);

                > * {
                    transition: opacity $trans-time--xl $trans-func--default;
                }
            }

            &:not(.is-expanded) {
                .couple__content {
                    padding-top: 0;
                    padding-bottom: 0;
                    max-height: 0;
                    overflow: hidden;

                    > * {
                        opacity: 0;
                    }
                }
            }
        }
    }

    @include respond-to(decent) {
        min-height: 100vh;
        display: flex;
        position: relative;
        background: $background--default;

        .content-section + & {
            border-top: 1px solid $border--default;
        }

        > * {
           width: 50%;
        }

        // prepare specific modal cover for product modals
        @include modal-cover();

        // images section
        .couple__img {
            display: flex;
            padding: couple-top-padding() 0 0 main-col-padding(decent, true);

            // make image section sticky
            @supports (position: sticky) {
                position: sticky;
                top: 0;
                height: 100vh;
                margin-bottom: -100vh;
                will-change: transform; // fix render issues
            }

            .media-box {
                padding-bottom: 0;
                height: 100%;
                max-height: 60vw; // prevent frankenstein portraits

                // Fade out images
                @supports(--css: variable) {
                    --img-opacity: 1;
                    opacity: var(--img-opacity);
                    will-change: opacity; // fix render issues
                }
            }
        }

        // couple variation with passepartout
        &.couple--passepartout {
            .couple__img {
                padding: couple-top-padding() main-col-padding(decent) $v-space--xxl main-col-padding(decent, true);

                .media-box {
                    max-height: 36vw; // prevent frankenstein portraits

                    @include respond-to(port) {
                        max-height: 54vw;
                    }
                }
            }
        }

        // couple variation with top aligned img
        &.couple--top-img {
            .couple__img {
                padding-top: 0;
                padding-bottom: couple-top-padding();
            }
        }

        // content section
        .couple__content {
            padding: 0 body-padding(decent) $v-space--xl 0;

            .main-col {
                padding-top: couple-top-padding(decent, true);
            }

        }

        // Show mobile number on ipad
        &:first-child {
            .couple__content {
                .ios & {
                    padding-bottom: $v-space--xxl + $v-space--m; // bring phone number into view on ipad
                }
            }
        }

        .main-col {
            height: 100%;
        }

        // reversing direction
        &.couple--rtl {
            flex-direction: row-reverse;

            // images section
            &:not(.couple--passepartout) {
                .couple__img {
                    padding: couple-top-padding() main-col-padding(decent, true) 0 0;
                }
            }

            &.couple--passepartout {
                .couple__img {
                    padding: couple-top-padding() main-col-padding(decent, true) $v-space--xxl main-col-padding(decent);
                }
            }

            // couple variation with top aligned img
            &.couple--top-img {
                .couple__img {
                    padding-top: 0;
                    padding-bottom: couple-top-padding();
                }
            }

            // content section
            .couple__content {
                padding: 0 0 $v-space--xl body-padding(decent);
                @include menu-cover();
            }
        }

        // handle hero
        &:first-child {
            .couple__content {
                a:first-child,
                .text-big:first-child {
                    flex: 1 1 auto;
                    margin-bottom: $v-space--m;
                }

                a:first-child {
                    padding-top: $v-space--m;
                }
            }
        }

        // handle cover for simple-hero
        .simple-hero + &:not(.couple--rtl) {
            background: transparent;
            margin-top: couple-top-padding(decent, false, true); // couple-top-padding inverted

            .couple__img {

                &:before {
                    @include pseudo();
                    top: -40rem;
                    height: 40rem;
                    left: 0;
                    right: 0;
                    background: $background--1;
                }

                &.section-fond-2 {
                    &:before {
                        background: $background--2;
                    }
                }

                &.section-fond-3 {
                    &:before {
                        background: $background--3;
                    }
                }
            }

            .couple__content {
                margin-top: couple-top-padding();
                border-top: 1px solid $border--default;
                background: $background--default;
            }
        }

        // give product couples more padding
        &.couple--extendable {
            .couple__content {
                padding-bottom: $v-space--xxl + $v-space--m;
            }
        }
    }

    @include respond-to(grid-m) {

        // images section
        .couple__img {
            padding: couple-top-padding(grid-m) 0 0 main-col-padding(grid-m, true);
        }

        // couple variation with passepartout
        &.couple--passepartout {
            .couple__img {
                padding: couple-top-padding(grid-m) main-col-padding(grid-m) $v-space--xxl main-col-padding(grid-m, true);
            }
        }

        // content section
        .couple__content {
            padding: 0 body-padding(grid-m) $v-space--xl 0;

            .main-col {
                padding-top: couple-top-padding(grid-m, true);
            }
        }

        // reversing direction
        &.couple--rtl {
            // images section
            &:not(.couple--passepartout) {
                .couple__img {
                    padding: couple-top-padding(grid-m) main-col-padding(grid-m, true) 0 0;
                }
            }

            &.couple--passepartout {
                .couple__img {
                    padding: couple-top-padding(grid-m) main-col-padding(grid-m, true) $v-space--xxl main-col-padding(grid-m);
                }
            }

            // content section
            .couple__content {
                padding: 0 0 $v-space--xl body-padding(grid-m);
                @include menu-cover(grid-m);
            }
        }

        // handle cover for simple-hero
        .simple-hero + &:not(.couple--rtl) {
            margin-top: couple-top-padding(grid-m, false, true); // couple-top-padding inverted

            .couple__content {
                margin-top: couple-top-padding(grid-m);
            }
        }
    }

    @include respond-to(port) {
        min-height: 80vw;
    }
}

[data-action="expandable:toggle"] {
    cursor: pointer; // activate interaction for ios
}

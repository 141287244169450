/// Definining the Basics
/// @group Vars
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

// ========================================================= Colors

// Text colors
$text--default: #000;
$text--highlight: #9e7f41; // Gold
$text--secondary: #8c8c8c;
$text--inverted: #fff;

// Background colors
$background--default: #fff;
$background--1: #e7ddcc; // Gold 25%
$background--2: #ede7d9; // Gold 18%
$background--3: #f5f1ea; // Gold 10%
$background--fade: rgba($background--1, 0.9);
$background--img: #f0f0f0;

// Border
$border--default: #c4b088; // Gold 60%

// Aliases
$text: $text--default;
$border: $border--default;


// ========================================================= Typography settings

// Fontsizes
$fontsize--xs: 1.375rem;        // InDesign: Text klein
$fontsize--s: 1.6rem;           // InDesign: Text // Default
$fontsize--m: 2rem;             // InDesign: Text groß
$fontsize--l: 2.375rem;         // InDesign: Head 2 (mobile)
$fontsize--xl: 2.75rem;         // InDesign: Head 1 (mobile)

// Bigger sizes for desktop
$fontsize--l-d: 2.6rem;          // InDesign: Head 2 // was 2.8
$fontsize--xl-d: 3.6rem;         // InDesign: Head 1

// Line height
$line-height--s: 1.5;           // Default
$line-height--m: 1.4;
$line-height--l: 1.3;
$line-height--xl: 1.3;
$line-height--l-d: 1.35;
$line-height--xl-d: 1.25;

$line-height--default: $line-height--s;
$line-height: $line-height--s;

// Letter spacing
$letter-spacing--xs: 0.025em;   // Very small text
$letter-spacing--s: 0.02em;     // Default
$letter-spacing--l-d: 0.015em;  // Headlines Desktop

$letter-spacing--default: $letter-spacing--s;
$letter-spacing: $letter-spacing--s;

// ========================================================= Layout

// vertical rythm
$v-space--xs: 0.8em;
$v-space--s: 1.6em;
$v-space--m: 3.2em;
$v-space--l: 4.8em;
$v-space--xl: 6.4em;
$v-space--xxl: 9em;

// grid // desktop only
$main-col-width: 45rem;
$main-col-width--m: 36rem;
$product-col-width: 34rem;
$product-col-width--m: 28rem;

// elements
$footer-height: 5em;

// misc
$link-spacing: 1.6rem;

// ========================================================= Animation

// Transition timing
$trans-time--xs: 0.125s;
$trans-time--s: 0.25s;
$trans-time--m: 0.5s;
$trans-time--l: 1s;
$trans-time--xl: 2s;
$trans-time--xxl: 3.5s;

$trans-time--nav: 0.35s;
$trans-time--nav-inner: 0.6s;

// Transition funcs // todo
$trans-func--default: cubic-bezier(.1,.6,.4,1);
$trans-func--nav: cubic-bezier(.165,.84,.44,1);
$trans-func--nav: cubic-bezier(.6,0,.14,1);

// ========================================================= Z-index

// z-indexing
$z-index: (
    header-main: 100,
    active-cover: 110,
    active-trigger: 120,
    modal: 200,
);

// ========================================================= Breakpoints

// Grid, Breakpoints and Responsiveness
$breakpoint-names: (
        s: s,
        mobile: s,
        small: s,
        grid-s: s,

        m: m,
        medium: m,
        tablet-portrait: m,
        grid-m: m,

        l: l,
        large: l,
        desktop: l,
        table-portrait: l,
        grid-l: l,

        lm: lm,
        large-m: lm,
        tablet: lm,

        xl: xl,
        extra-large: xl,
        desktop-large: xl,
        decent: decent,
        port: port,
);

// ========================================================= Grid settings

// Setup grid config
$grid-config: (
    // Small Grid (Mobile)
    grid-s: (
        columns: 6,
        body-padding: 2em,
        header-height: 6rem,
        breakpoint: s,
        main-col-width: $main-col-width,
    ),

    // Decent Grid (Desktop)
    decent: (
        columns: 12,
        body-padding: 2.5rem,
        header-height: 8rem,
        breakpoint: decent,
        main-col-width: $main-col-width,
    ),

    // Vertical tablets (Adjustments for decent)
    grid-m: (
        columns: 12,
        body-padding: 3rem,
        header-height: 8rem,
        breakpoint: grid-m,
        main-col-width: $main-col-width--m,
    ),
) !default;

.newsletter {
    .mce-submit {
        display: flex;
        justify-content: center;
    }
}

.newsletter__headline {
    h2 {
        @include text--l();
    }
}

.newsletter__form-wrapper,
.newsletter__form-inner {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.newsletter__form {
    flex-grow: 1;
    margin-top: $v-space--m;

    label,
    input {
        transition: all $trans-time--xs $trans-func--default;
        touch-action: manipulation;
        @include text--s;
    }

    label {
        order: 1;
        color: $text--highlight;
        transform: translate(0, 0) scale(0.85);
        font-style: italic;
        transform-origin: left bottom;
    }

    .field {
        display: flex;
        flex-flow: column-reverse;
        margin-bottom: 0.5rem;
    }

    input {
        order: 1;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $text--highlight;
        padding: 0.4rem 0 0.8rem;
        font-family: inherit;
        -webkit-appearance: none;

        @include placeholder-nested {
            color: transparent;
        }

        &:focus {
            outline: 0;

            @include placeholder-nested {
                color: $text--secondary;
            }
        }

        &[type="email"] + label {
            display: flex;
            justify-content:space-between;
        }
    }

    input:placeholder-shown:not(:focus) + label {
        cursor: pointer;
        transform: translate(0, calc(100% + 0.4rem));
    }
}

.newsletter__fields {
    .show-newsletter-names & {
        label {
            transform: translate(0, 0) scale(0.8);
            transform-origin: left bottom;

            .newsletter__email-label--arrow {
                display: none;
            }
        }
    }

    .response:not(:empty) ~ & {
        display: none;
    }
}

.newsletter__names {
    display: none;

    .show-newsletter-names & {
        display: block;
    }
}

.newsletter__info {
    margin-top: auto;
    padding-top: 3rem;

    .secondary-text {
        display: block;

        .show-newsletter-names & {
            display: none;
        }
    }

    .response:not(:empty) ~ & {
        display: none;
    }
}

.newsletter__legal-info {
    color: $text--secondary;
    display: none;
    font-size: $fontsize--xs;
    letter-spacing: $letter-spacing--xs;
    line-height: $line-height--s;

    .show-newsletter-names & {
        display: block;
    }
}

.newsletter__button {
    display: none;
    margin-top: 1.6rem;

    .show-newsletter-names & {
        display: block;
    }

    .response:not(:empty) ~ & {
        display: none;
    }
}

div.mce_inline_error {
    @include text--xs;
    font-weight: inherit !important;
    order: 0;
    margin: 0.5rem 0 0 0 !important;
    background: none !important;
    color: red !important;
    padding: 0 !important;
}

div.response {
    display: none !important;
}

div.custom-response {
    display: none;
    color: $text--highlight;
    text-align: center;

    .response:not(:empty) ~ & {
        display: block;
    }
}

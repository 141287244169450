/// Show a couple of great images
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////


/* Gallery Layout

Starting with a wider / smaller
then smaller / wider in the next row
|  1b  2    |
|  3   4b   |
|  5b  6    |
|  7   8b   |
|  9b  10   |
|  11  12b  |
|  13b 14   |
|  15  16b  |
| ...

Variation right-to-left switches sizes order

// Sizes mobile on a 6-column grid: 2.5/6 and 3/6
// Sizes big image: 5/6
// Same for desktop modals

// Sizes desktop on a 10-column grid: 4/10 and 5/10

*/

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 body-padding();

    // smaller default
    > * {
        margin-top: $v-space--m;
        width: 2.5/6 * 100%;
    }

    // Multiple images / starting with wider one one
    &.gallery--ltr {
        // bigger
        > :nth-child(4n-3),
        > :nth-child(4n) {
            width: 3/6 * 100%;
        }
    }

    // Multiple images / starting with smaller one one
    &.gallery--rtl {
        // bigger
        > :nth-child(4n-2),
        > :nth-child(4n+3) {
            width: 3/6 * 100%;
        }
    }

    // Single image
    > :first-child:last-child {
        width: 100% !important;
        display: flex;
        flex-direction: column;

        figcaption,
        .media-box {
            width: 5/6 * 100%;
        }
    }

    &.gallery--rtl {
        > :first-child:last-child {
            figcaption,
            .media-box {
                align-self: flex-end;
            }
        }
    }

    .media-box {
        background: $background--img;
    }

    &:last-child {
        padding-bottom: $v-space--xl;
    }

    & + .main-col {
        padding-top: $v-space--l;
    }

    @include respond-to(decent) {
        padding: 0 main-col-padding(decent, true);

        // smaller default
        > * {
            margin-top: $v-space--xl;
        }

        // modal treatments
        .modal & {
            padding: 0 main-col-padding(decent) 0 body-padding(decent);
        }

        .has-modal--rtl .modal & {
            padding: 0 body-padding(decent) 0 main-col-padding(decent);
        }

        // gallery in modal have the same grid definitions as mobile
        main & {
            // smaller default
            > * {
                width: 4/10 * 100%;
            }

            // Multiple images / starting with wider one one
            &.gallery--ltr {
                // bigger
                > :nth-child(4n-3),
                > :nth-child(4n) {
                    width: 5/10 * 100%;
                }
            }

            // Multiple images / starting with smaller one one
            &.gallery--rtl {
                // bigger
                > :nth-child(4n-2),
                > :nth-child(4n+3) {
                    width: 5/10 * 100%;
                }
            }

            // Single image
            > :first-child:last-child {
                figcaption,
                .media-box {
                    width: 7/10 * 100%;
                }
            }
        }

        &:last-child {
            padding-bottom: $v-space--xxl;
        }

        & + .main-col {
            padding-top: $v-space--xl;
        }
    }

    @include respond-to(grid-m) {
        padding: 0 main-col-padding(grid-m, true);

        // modal treatments
        .modal & {
            padding: 0 main-col-padding(grid-m) 0 body-padding(grid-m);
        }

        .has-modal--rtl .modal & {
            padding: 0 body-padding(grid-m) 0 main-col-padding(grid-m);
        }
    }
}

figure {
    p {
        margin-top: 1rem;
        @include text--s;
    }
}

figcaption {
    margin-bottom: 2rem;

    @include respond-to(decent) {
        margin-bottom: 4rem;
    }
}

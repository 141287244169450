/// Icon Definitions
/// @group Icons
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.svg-ico {
    display: inline-block;

    & > svg {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.svg-ico--gol-logo {
    width: 8.5rem;
    height: 110.5rem;

    @include respond-to(decent) {
        width: 11rem;
        height: 143rem;
    }
}

.svg-ico--gol-close,
.svg-ico--gol-close-desktop,
.svg-ico--gol-navicon {
    width: 3rem;
    height: 3rem;
}

// Inline Icons / See: https://github.com/TrySound/postcss-inline-svg
@svg-load icon--gol-extend url(../icons/gol-extend.svg) {
    stroke: $text--inverted;
    fill: transparent;
}

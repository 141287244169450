/// Main page header
/// @group Header
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.header-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: map-get($z-index, header-main);
    min-height: calc(#{body-padding() * 2} + 7rem); // logo height

    // Mobile behaviour
    @include respond-to(grid-s) {

        // If header is out of view, fix it and remove it from viewport
        // without any transition to prevent blinking while getting fixed
        .is-scrolled-beyond-header & {
            transform: translate3d(0, -100%, 0); // only remove as far as neccessary
            position: fixed;
        }

        // Add the animation as the header is gone far enough
        .is-scrolled-decent & {
            transition: transform $trans-time--s $trans-func--default;
        }

        // Show header if going back up
        .is-scrolled-up-decent & {
            transition: transform $trans-time--s $trans-func--default;
            transform: none;
            position: fixed;
        }
    }

    @include respond-to(decent) {
        position: fixed;
        min-height: calc(#{body-padding(decent) * 2} + 7rem); // logo height
    }

    @include respond-to(grid-m) {
        min-height: calc(#{body-padding(grid-m) * 2} + 7rem); // logo height
    }
}

.logo {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: right;
    margin: calc(#{body-padding()} - 2rem);
    line-height: 0;
    background: $background--default;
    overflow: hidden;
    height: 8.5rem;

    @include respond-to(decent) {
        background: transparent;
        margin: calc(#{body-padding(decent)} - 2rem);
        left: auto;
        width: 11rem;
        height: 11rem;

        .svg-ico {
            .has-scrolled & {
                animation: logo 0.2s steps(12) both;
            }

            .is-scrolled & {
                animation: logo-shrink 0.2s steps(12) both;
            }
        }
    }

    @include respond-to(grid-m) {
        margin: calc(#{body-padding(grid-m)} - 2rem);
    }
}

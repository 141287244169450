/// Grid Definitions
/// @group Grid
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.main-col {
    @include respond-to(decent) {
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            width: $main-col-width;
            max-width: 100%;
        }

        > a {
            display: block;
        }
    }

    @include respond-to(grid-m) {
        > * {
            width: $main-col-width--m;
        }
    }
}

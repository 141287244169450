/// Main page footer
/// @group Footer
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

// Footer Main
.footer-main {
    background: $background--default;
    position: relative;

    // safari-fix to prevent render issues
    .is-safari & {
        will-change: transform;
    }
}

// Contact Footer
.contact-footer {
    border-top: 1px solid $border--default;
    padding: $v-space--xl body-padding();
    text-align: center;

    > *:not(:first-child) {
        margin-top: $v-space--m;
    }

    @include respond-to(decent) {
        padding: $v-space--xxl body-padding(decent);

        > * {
            width: 25%;

            &:not(:first-child) {
                margin-top: 0;
            }
        }
    }

    @include respond-to(grid-m) {
        padding: $v-space--xxl body-padding(grid-m);
    }
}

.contact-footer__head {
    h4 {
        @include text--m;
    }

    @include respond-to(decent) {
        margin-top: $fontsize--s * $line-height;
    }
}

// Newsletter Footer
// additional to existing newsletter styles
.newsletter-footer {
    border-top: 1px solid $border--default;
    padding: $v-space--xl body-padding();

    .newsletter__form-container {
        max-width: 30rem;
        margin: auto;

        h2 {
            text-align: center;
            @include text--m();
        }

        @include respond-to(decent) {
            max-width: 40rem;
        }
    }

    .newsletter__form-content {
        margin: auto;
        padding: 0 2rem;

        input:not([type='email']) + label {
            width: fit-content;
        }
    }
}

// Nav Footer
.nav-footer {
    display: flex;
    justify-content: center;
    background: $background--3;
    padding: $v-space--s body-padding();
    min-height: $footer-height;

    ul {
        display: flex;
        align-items: center;

        li {
            &:not(:first-child) {
                margin-left: $link-spacing;
            }

            @include underline-active;
        }

        a {
            @include text--s;
        }
    }

    @include respond-to(decent) {
        display: flex;
        justify-content: space-between;
        padding: $v-space--s body-padding(decent);
    }

    @include respond-to(grid-m) {
        padding: $v-space--s body-padding(grid-m);
    }
}

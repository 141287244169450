/// Product styles
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.product__main-img {
    .media-box {
        padding-bottom: 100%;
    }

    @include respond-to(decent) {
        // show main image on the right and pull up following content
        margin-bottom: -50vw;

        .media-box {
            padding-bottom: 0;
            height: 50vw;
            width: 50vw;
        }

        & + * {
            min-height: 50vw;
        }

        // define for use in modal
        .modal & {
            margin-bottom: 0;
            margin-left: body-padding();

            & + * {
                min-height: auto;
            }
        }

        // dont show main image if we have it there anyways
        .has-modal--btn-triggered & {
            margin-top: $v-space--m * -1;

            .media-box {
                display: none;
            }
        }
    }
}

.product__title {
    h1,
    h2,
    h3,
    h4 {
        display: inline;
    }

    > :first-child:not(:last-child) {
       &:after {
           content: " · ";
           white-space: pre;
       }
    }
}

// product overview
.product-group {
    width: 4/6 * 100%;
    margin: 0 auto;

    @include respond-to(decent) {
        width: $main-col-width;
    }

    @include respond-to(grid-m) {
        width: $main-col-width--m;
    }
}

[data-modal] {
    @include respond-to(decent) {
        // add closing hint
        .media-box {
            &:after {
                position: absolute;
                content: "Schließen";
                top: 0;
                right: 0;
                padding: 2.4rem 3rem;
                @include text--s;
                @include text--italic;
                color: $text--highlight;
                opacity: 0;
                transition: opacity $trans-time--nav $trans-func--nav;

                .couple--rtl & {
                    left: 0;
                    right: auto;
                }
            }
        }

        // handle modal active state
        &.is-active-trigger {
            .media-box {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

.main-col.product-group-intro {
    height: auto;
}

.product-group__teaser {
    position: relative;
    display: block;
    margin-top: $v-space--l;

    &:first-child {
        margin-top: $v-space--m;
    }

    .media-box {
        padding-bottom: 100%;
    }

    .product__title {
        margin-top: 1.6rem;

        span {
            color: $text--highlight;
            @include text--s;
            @include text--italic;
            display: block;
        }
    }

    @include respond-to(decent) {
        margin-top: $v-space--l;

        .media-box {
            // transform smaller to be able to zoom in on opened modal
            transition: transform $trans-time--nav $trans-func--nav;
            transform-origin: center center;
            transform: scale(0.77);
        }

        .product__title {
            margin: -3.4rem auto 0;
            width: $product-col-width;
            transition: transform $trans-time--nav $trans-func--nav,
                        opacity $trans-time--nav $trans-func--nav;
        }

        // include shadow
        &:before {
            @include pseudo();
            width: 100%;
            padding-bottom: 100%;
            transform: scale(0.5);
            box-shadow: 0 0 10rem rgba(darken($background--1, 50%), 0.15);
            opacity: 0;
            transition: transform $trans-time--nav $trans-func--nav,
                        opacity $trans-time--nav $trans-func--nav;
        }

        // handle modal active state
        &.is-active-trigger {
            .media-box {
                transform: none;

                &:after {
                    opacity: 1;
                }
            }

            .product__title {
                transform: translate3d(0, 50%, 0);
                opacity: 0;
            }


            &:before {
                opacity: 1;
                transform: none;
            }
        }

        &.is-active-trigger--still {
            z-index: map-get($z-index, active-trigger);
        }

        // handle first element in group
        &:first-child {
            margin-top: calc((100vh - #{$product-col-width}) / 2);

            // first child in first group
            .simple-hero + .couple:not(.couple--rtl) & {
                margin-top: couple-top-padding(); // seems to work out
            }

            // first child if we have a text before
            .product-group-intro + .product-group & {
                margin-top: $v-space--l;
            }
        }
    }

    @include respond-to(grid-m) {
        margin-top: $v-space--l;

        .product__title {
            width: $product-col-width--m;
        }
    }
}

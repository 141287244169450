/// Shorthand Method for keyframes
/// Needed here since mixins need animations and therefore come next in mains.scss
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name for the keyframes
@mixin keyframes($name) {
    @keyframes #{$name} {
        @content;
    }
}

// default modal animation
@include keyframes(has-modal-ltr) {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(-100%, 0, 0);
    }
    1% {
        opacity: 0;
        visibility: visible;
        transform: translate3d(-100%, 0, 0);

    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@include keyframes(has-modal--rtl) {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }
    1% {
        opacity: 0;
        visibility: visible;
        transform: translate3d(100%, 0, 0);

    }
    100% {
        opacity: 1;
        transform: none;
    }
}

@include keyframes(logo) {
    0% {
        transform: translate3d(-6%, -91.7%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(logo-shrink) {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-6%, -91.7%, 0); // -92.308% without offset
    }
}

/* Goldkant Base */

// Viewport based typography: http://zellwk.com/blog/viewport-based-typography/
// scss-lint:disable MergeableSelector

$almost-stop-resizing: 1920px;
$stop-resizing: 3200px;

// Html: Resizing for fontsizes: rem
// body: Resizing for raster elements: em
html {
    // Remove system specific highligh color
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // Apply fallback fontsize
    font-size: 8px;

    // Fix for safari bug: Permanent grid scale fails
    // https://css-tricks.com/snippets/css/fluid-typography/#comment-1753016
    &.is-safari {
        min-height: 1vw; // 0vw gets stripped in production build
    }

    // Grid S
    @include fluid-type(font-size, 320px, $bp--small-e, 9.2px, 12.5px);

    // Grid M
    @include fluid-type(font-size, $bp--medium-s, $bp--medium-e, 6.6px, 10.6px);

    // Desktop
    @include fluid-type(font-size, $bp--large-s, $bp--large-e, 8px, 10px);
    @include fluid-type(font-size, $bp--extra-large-s, $almost-stop-resizing, 10px, 12px);
    @include fluid-type(font-size, $almost-stop-resizing + 1, $stop-resizing, 12px, 13.5px);

    // stop resizing above certain threshold
    @media only screen and (min-width: $stop-resizing) {
        font-size: 13.5px;
    }
}

body {
    background-color: $background--default;
    overflow-x: hidden;
}

// hide specific elements
.hidden {
    display: none;
}

// hide stuff on grid-m and grid-l
.hidden-on-desktop {
    @include respond-to(decent) {
        display: none !important;
    }
}

// hide stuff on grid-m
.hidden-on-mobile {
    @include respond-to(grid-s) {
        display: none !important;
    }
}

// hide visually but still have it there for accessibility
.hidden-on-grid-l {
    @include respond-to(grid-l) {
        display: none !important;
    }
}

// hide visually but still have it there for accessibility
.hidden-visually {
    @include hide;
}

// hide visually but still have it there for accessibility
.hidden-visually-on-mobile {
    @include respond-to(grid-s) {
        @include hide;
    }
}

// hide visually but still have it there for accessibility
.hidden-visually-on-desktop {
    @include respond-to(decent) {
        @include hide;
    }
}

// backgroundcolors
.section-fond-1 {
    background: $background--1;
}

.section-fond-2 {
    background: $background--2;
}

.section-fond-3 {
    background: $background--3;
}

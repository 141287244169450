@charset "UTF-8";
/* ==========================================================================
   HTML5 display definitions
   ========================================================================== */
html, a, span, div, select, input, li, figure, figcaption {
  outline: none;
}

/**
 * Correct `block` display not defined in IE 8/9.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/* ==========================================================================
   Base
   ========================================================================== */
/**
 * Prevent iOS text size adjust after orientation change, without disabling user zoom.
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* ==========================================================================
   Typography
   ========================================================================== */
/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

address {
  font-style: normal;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9.
 */
img {
  border: 0;
}

/**
 * Correct overflow displayed oddly in IE 9.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
   Figures
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari 5.
 */
figure {
  margin: 0;
}

/* ==========================================================================
   Forms
   ========================================================================== */
a,
button,
input,
select,
textarea {
  outline: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type=text]::-ms-clear,
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * 1. Correct font family not being inherited in all browsers.
 * 2. Correct font size not being inherited in all browsers.
 * 3. Address margins set differently in Firefox 4+, Safari 5, and Chrome.
 */
button,
input,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 8+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* ==========================================================================
   Clearfix
   ========================================================================== */
/* Clearfix: contain floats */
.cf:before,
.cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.cf:after {
  clear: both;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

/* ==========================================================================
   Default box sizing
   ========================================================================== */
*,
*:after,
*:before {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Marr Sans';
  src: url("fonts/MarrSans-Regular-Web.woff") format("woff2"), url("fonts/MarrSans-Regular-Web.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Marr Sans Italic';
  src: url("fonts/MarrSans-RegularItalic-Web.woff") format("woff2"), url("fonts/MarrSans-RegularItalic-Web.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

/* @group humans-machines
@mixin list-hover($offset: -0.1em) {
    a {
        .no-mobile.no-touch & {
            display: block;

            span {
                position: relative;

                &:before {
                    @include pseudo();
                    bottom: $offset;
                    left: 50%;
                    transform: translateX(-50%) scaleX(0);
                    height: 1px;
                    width: 100%;
                    opacity: 0;
                    background: rgba($text--default, 0.8);
                    transition: transform 0.15s $trans-func--default, opacity 0.15s $trans-func--default;
                }
            }
        }

        @include hover {
            span {
                &:before {
                    // width: 100%;
                    transform: translateX(-50%) scaleX(1);
                    opacity: 1;
                    transition: transform 0.15s $trans-func--default 0.05s, opacity 0.15s $trans-func--default 0.05s;
                }
            }
        }

        .ie &,
        .edge & {
            span:before {
                transition: none !important;
            }
        }
    }
}*/
@keyframes has-modal-ltr {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes has-modal--rtl {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
  1% {
    opacity: 0;
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes logo {
  0% {
    transform: translate3d(-6%, -91.7%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes logo-shrink {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-6%, -91.7%, 0);
  }
}

/*! Flickity v2.1.2
https://flickity.metafizzy.co
Removed dots and buttons
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* Goldkant Base */
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 8px;
  font-size: 9.2px;
  font-size: 6.6px;
  font-size: 8px;
  font-size: 10px;
  font-size: 12px;
}

html.is-safari {
  min-height: 1vw;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(9.2px + 3.3 * (100vw - 320px) / 279);
  }
}

@media screen and (min-width: 599px) {
  html {
    font-size: 12.5px;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: calc(6.6px + 4 * (100vw - 600px) / 399);
  }
}

@media screen and (min-width: 999px) {
  html {
    font-size: 10.6px;
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: calc(8px + 2 * (100vw - 1000px) / 399);
  }
}

@media screen and (min-width: 1399px) {
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 1400px) {
  html {
    font-size: calc(10px + 2 * (100vw - 1400px) / 520);
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1921px) {
  html {
    font-size: calc(12px + 1.5 * (100vw - 1921px) / 1279);
  }
}

@media screen and (min-width: 3200px) {
  html {
    font-size: 13.5px;
  }
}

@media only screen and (min-width: 3200px) {
  html {
    font-size: 13.5px;
  }
}

body {
  background-color: #fff;
  overflow-x: hidden;
}

.hidden {
  display: none;
}

@media only screen and (min-width: 600px) {
  .hidden-on-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 599px) {
  .hidden-on-mobile {
    display: none !important;
  }
}

@media only screen and (min-width: 1000px) {
  .hidden-on-grid-l {
    display: none !important;
  }
}

.hidden-visually {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px !important;
  padding: 0;
  border: 0;
}

@media only screen and (max-width: 599px) {
  .hidden-visually-on-mobile {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
  }
}

@media only screen and (min-width: 600px) {
  .hidden-visually-on-desktop {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
  }
}

.section-fond-1 {
  background: #e7ddcc;
}

.section-fond-2 {
  background: #ede7d9;
}

.section-fond-3 {
  background: #f5f1ea;
}

/* Goldkant typography */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Marr Sans", Arial, sans-serif;
  color: #000;
  line-height: 1.5;
  letter-spacing: 0.02em;
}

a {
  color: inherit;
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

p {
  font-size: 2rem;
  line-height: 1.4;
}

p + p {
  margin-top: 1rem;
}

h2 + p {
  margin-top: 2rem;
}

.brand-listing + p,
.listing + p,
p.text-big + p:not(.text-big) {
  margin-top: 4rem;
}

h1,
h2 {
  font-size: 2.375rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  h1,
  h2 {
    font-size: 2.6rem;
    line-height: 1.35;
    letter-spacing: 0.015em;
  }
}

.main-col h1:not(:first-child) {
  margin-top: 8rem;
}

h3,
h4,
h5,
h6 {
  font-size: 1.6rem;
  line-height: 1.5;
}

.main-col h2, .main-col
h3 {
  font-size: 1.6rem;
  line-height: 1.5;
}

.main-col h2:not(:first-child), .main-col
h3:not(:first-child) {
  margin-top: 4rem;
}

.text-xsmall {
  font-size: 1.375rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
}

@media only screen and (min-width: 600px) {
  .text-xsmall {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
}

.text-small {
  font-size: 1.6rem;
  line-height: 1.5;
}

.text-medium {
  font-size: 2rem;
  line-height: 1.4;
}

.text-big {
  font-size: 2.375rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .text-big {
    font-size: 2.6rem;
    line-height: 1.35;
    letter-spacing: 0.015em;
  }
}

@media only screen and (max-width: 599px) {
  .text-big br {
    display: none;
  }
}

.text-link {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Marr Sans Italic", Arial, sans-serif;
  font-style: italic;
  color: #9e7f41;
  display: block;
}

p + .text-link,
h1 + .text-link,
h2 + .text-link,
h3 + .text-link {
  margin-top: 2rem;
}

.text-link + .text-link {
  margin-top: 0.25rem;
}

.listing + .text-link {
  margin-top: 4rem;
}

.post-date {
  margin-top: 1rem;
}

.post-date + p {
  margin-top: 2rem;
}

@media only screen and (min-width: 600px) {
  .main-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-col > * {
    width: 45rem;
    max-width: 100%;
  }
  .main-col > a {
    display: block;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .main-col > * {
    width: 36rem;
  }
}

.btn,
button {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background: none;
  appearance: none;
  font-size: 1.6rem;
  line-height: 1.5;
  color: inherit;
  cursor: pointer;
}

.btn.btn--outline,
button.btn--outline {
  border: 1px dashed #c4b088;
  border-radius: 2px;
  background: transparent;
  white-space: nowrap;
  padding: 0.2rem 0.5rem 0;
  width: auto;
  display: inline-block;
}

.btn.btn--icon,
button.btn--icon {
  display: flex;
  align-items: center;
}

.btn.btn--icon > span:first-child,
button.btn--icon > span:first-child {
  margin-right: 1rem;
  line-height: 0;
}

.btn.btn--icon > span:last-child,
button.btn--icon > span:last-child {
  margin-top: 0.2rem;
}

.ios .btn.btn--icon > span:last-child, .ios
button.btn--icon > span:last-child {
  margin-top: 0.4rem;
}

.btn.btn--cta,
button.btn--cta {
  border: 1px solid #c4b088;
  border-radius: 2px;
  padding: 1rem 2rem;
  display: inline-block;
  color: #9e7f41;
  font-family: "Marr Sans Italic", Arial, sans-serif;
  font-style: italic;
  margin-top: 2rem;
}

.svg-ico {
  display: inline-block;
}

.svg-ico > svg {
  position: relative;
  width: 100%;
  height: 100%;
}

.svg-ico--gol-logo {
  width: 8.5rem;
  height: 110.5rem;
}

@media only screen and (min-width: 600px) {
  .svg-ico--gol-logo {
    width: 11rem;
    height: 143rem;
  }
}

.svg-ico--gol-close,
.svg-ico--gol-close-desktop,
.svg-ico--gol-navicon {
  width: 3rem;
  height: 3rem;
}

@svg-load icon--gol-extend url(../icons/gol-extend.svg) {
  stroke: #fff;
  fill: transparent;
}

ul,
ol,
li,
dl,
dt,
dd {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 1.4;
}

.listing > * {
  text-align: center;
  margin-top: 4rem;
}

.listing > * h4 ~ *:not(.text-link) {
  font-size: 2rem;
  line-height: 1.4;
}

.listing > * .text-link {
  margin-top: 1rem;
}

.listing > * a,
.listing > * data {
  display: block;
}

@media only screen and (max-width: 599px) {
  .listing:first-child > :first-child {
    margin-top: 0;
  }
}

@media only screen and (min-width: 600px) {
  .listing {
    display: flex;
    justify-content: space-between;
  }
  .listing > * {
    width: 50%;
    padding: 0 0.5rem;
  }
}

.listing__social a {
  display: inline-block;
  padding: 0 0.4rem;
}

.listing__social h4 + a {
  display: block;
}

.listing__social > :nth-child(4) {
  padding-left: 0;
}

.listing__social > :nth-child(4):before {
  content: " · ";
}

.main-col dl {
  text-align: center;
}

.main-col dl:not(:first-child) {
  margin-top: 4rem;
}

@media only screen and (min-width: 600px) {
  .main-col dl {
    max-width: 38rem;
  }
  .text-big + .main-col dl {
    margin-top: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .main-col dl {
    max-width: 32rem;
  }
}

dt {
  font-size: 1.6rem;
  line-height: 1.5;
}

.main-col dt:not(:first-child) {
  margin-top: 4rem;
}

.main-col ul:not(:first-child) {
  margin-top: 2rem;
}

@media only screen and (min-width: 600px) {
  .text-big + .main-col ul {
    margin-top: 0;
  }
}

.main-col li:not([data-brand-trigger]) {
  list-style-type: circle;
  margin-left: 3rem;
}

.main-col li:not([data-brand-trigger]) p {
  display: inline-block;
}

.media-box {
  position: relative;
  overflow: hidden;
}

.media-box img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 50% 50%;
  transition: transform 1s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.ie .media-box img {
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}

.media-box .lazy {
  opacity: 0;
  transform: scale(1.05);
}

.media-box .lazy--loaded {
  opacity: 1;
  transform: none;
}

.media-box--bg {
  background: #f0f0f0;
}

.media-box--bg img {
  mix-blend-mode: multiply;
}

.media-box__cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
  transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
  opacity: 0;
}

.lazy--loaded ~ .media-box__cover {
  opacity: 0.13;
}

.media-box__inner {
  width: 100%;
  position: relative;
}

.media-box__title {
  position: absolute;
  top: 47%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 2rem;
  color: #fff;
  text-align: center;
}

.media-box__title h2,
.media-box__title h3 {
  font-size: 2.75rem;
  line-height: 1.3;
  font-family: "Marr Sans Italic", Arial, sans-serif;
  font-style: italic;
}

@media only screen and (min-width: 600px) {
  .media-box__title h2,
  .media-box__title h3 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.header-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  min-height: calc(4em + 7rem);
}

@media only screen and (max-width: 599px) {
  .is-scrolled-beyond-header .header-main {
    transform: translate3d(0, -100%, 0);
    position: fixed;
  }
  .is-scrolled-decent .header-main {
    transition: transform 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .is-scrolled-up-decent .header-main {
    transition: transform 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
    transform: none;
    position: fixed;
  }
}

@media only screen and (min-width: 600px) {
  .header-main {
    position: fixed;
    min-height: calc(5rem + 7rem);
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .header-main {
    min-height: calc(6rem + 7rem);
  }
}

.logo {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: right;
  margin: calc(2em - 2rem);
  line-height: 0;
  background: #fff;
  overflow: hidden;
  height: 8.5rem;
}

@media only screen and (min-width: 600px) {
  .logo {
    background: transparent;
    margin: calc(2.5rem - 2rem);
    left: auto;
    width: 11rem;
    height: 11rem;
  }
  .has-scrolled .logo .svg-ico {
    animation: logo 0.2s steps(12) both;
  }
  .is-scrolled .logo .svg-ico {
    animation: logo-shrink 0.2s steps(12) both;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .logo {
    margin: calc(3rem - 2rem);
  }
}

body.has-menu {
  height: 100%;
  overflow: hidden;
}

@media only screen and (min-width: 1000px) {
  body {
    overflow: visible;
    height: auto;
  }
  body.has-secondary-menu {
    overflow: hidden;
  }
}

.nav-main {
  position: relative;
  padding: 2em;
  width: 50%;
}

@media only screen and (min-width: 600px) {
  .nav-main {
    padding: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-main {
    padding: 3rem;
  }
}

.nav-main__navicon {
  position: relative;
}

.nav-main__navicon .svg-ico--gol-close {
  display: none;
}

.has-menu .nav-main__navicon .svg-ico--gol-close {
  display: block;
}

.has-menu .nav-main__navicon .svg-ico--gol-navicon {
  display: none;
}

.nav-main__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  padding: 2em;
  min-height: 72vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
}

body:not(.has-menu) .nav-main__wrapper {
  transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s linear 0.35s;
  transform: translate3d(0, -8rem, 0);
  opacity: 0;
  visibility: hidden;
}

.nav-main__wrapper a,
.nav-main__wrapper .btn[data-action="nav:toggleSecondary"] {
  font-size: 1.6rem;
  line-height: 1.5;
  display: block;
}

@media only screen and (min-width: 1000px) {
  .nav-main__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    background: transparent;
    min-height: 1px;
    overflow: visible;
    padding: 2.5rem;
  }
  body:not(.has-menu) .nav-main__wrapper {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-main__wrapper {
    height: 70rem;
    max-height: 90vh !important;
    padding: 3rem;
  }
  .nav-main__wrapper a {
    font-size: 2rem;
    line-height: 1.4;
  }
}

.nav-main__primary-links {
  margin-top: 7rem;
  position: relative;
  height: 100%;
  padding-bottom: 8rem;
  flex: 1 1 auto;
}

.nav-main__primary-links > li {
  width: 50%;
  line-height: 1.5;
  padding-right: 2rem;
  transition: transform 0.6s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.6s cubic-bezier(0.6, 0, 0.14, 1);
}

.nav-main__primary-links > li:nth-child(0) {
  transition-delay: -0.05s;
}

.nav-main__primary-links > li:nth-child(1) {
  transition-delay: 0s;
}

.nav-main__primary-links > li:nth-child(2) {
  transition-delay: 0.05s;
}

.nav-main__primary-links > li:nth-child(3) {
  transition-delay: 0.1s;
}

.nav-main__primary-links > li:nth-child(4) {
  transition-delay: 0.15s;
}

.nav-main__primary-links > li:nth-child(5) {
  transition-delay: 0.2s;
}

.nav-main__primary-links > li:nth-child(6) {
  transition-delay: 0.25s;
}

.nav-main__primary-links > li:nth-child(7) {
  transition-delay: 0.3s;
}

.nav-main__primary-links > li:nth-child(8) {
  transition-delay: 0.35s;
}

.nav-main__primary-links > li > a,
.nav-main__primary-links > li > .btn:not(.btn--single-icon) {
  display: block;
}

.nav-main__primary-links > li > a span,
.nav-main__primary-links > li > .btn:not(.btn--single-icon) span {
  position: relative;
}

.nav-main__primary-links > li > a span:before,
.nav-main__primary-links > li > .btn:not(.btn--single-icon) span:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -0.2rem;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  height: 1px;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.no-mobile.no-touch .nav-main__primary-links > li:hover > a span:before,
.no-mobile.no-touch .nav-main__primary-links > li:hover > .btn:not(.btn--single-icon) span:before,
body:not(.has-secondary-menu) .nav-main__primary-links > li.is-active > a span:before,
body:not(.has-secondary-menu) .nav-main__primary-links > li.is-active > .btn:not(.btn--single-icon) span:before, .nav-main__primary-links > li.has-secondary > a span:before,
.nav-main__primary-links > li.has-secondary > .btn:not(.btn--single-icon) span:before {
  transform: translateX(-50%) scaleX(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s, opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s;
}

.ie .nav-main__primary-links > li span:before,
.edge .nav-main__primary-links > li span:before {
  transition: none !important;
}

body:not(.has-menu) .nav-main__primary-links > li {
  transition: none;
  transform: translate3d(0, 8rem, 0);
  opacity: 0;
}

.nav-main__primary-links > li > a,
.nav-main__primary-links > li > .btn[data-action="nav:toggleSecondary"] {
  font-size: 2rem;
  line-height: 1.4;
}

.nav-main__primary-links > li:nth-child(4) {
  margin-top: 1.5rem;
}

.nav-main__primary-links:after {
  content: '';
  position: absolute;
  pointer-events: none;
  background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
  left: 0;
  right: 0;
  bottom: 0;
  height: 4em;
}

@media only screen and (min-width: 1000px) {
  .nav-main__primary-links {
    margin-top: 0;
    position: static;
    height: auto;
    padding-bottom: 0;
    display: flex;
  }
  .nav-main__primary-links > li {
    width: auto;
    line-height: 1.5;
    padding-right: 1rem;
    z-index: 2;
  }
  .nav-main__primary-links > li:last-child {
    z-index: 1;
  }
  body:not(.has-menu) .nav-main__primary-links > li {
    transform: none;
    opacity: 1;
  }
  .nav-main__primary-links > li > a,
  .nav-main__primary-links > li > .btn[data-action="nav:toggleSecondary"] {
    font-size: 1.6rem;
    line-height: 1.5;
    position: relative;
  }
  .nav-main__primary-links > li:nth-child(4) {
    margin-top: 0;
    margin-left: 2rem;
  }
  .nav-main__primary-links:after {
    display: none;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-main__primary-links > li > a,
  .nav-main__primary-links > li > .btn[data-action="nav:toggleSecondary"] {
    font-size: 2.375rem;
    line-height: 1.3;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) and (min-width: 600px) {
  .nav-main__primary-links > li > a,
  .nav-main__primary-links > li > .btn[data-action="nav:toggleSecondary"] {
    font-size: 2.6rem;
    line-height: 1.35;
    letter-spacing: 0.015em;
  }
}

.nav-main__secondary-links {
  position: absolute;
  top: 0;
  left: 50%;
  max-height: 100%;
  overflow-y: auto;
  width: calc(50vw - 2em);
}

body:not(.has-secondary-menu) .nav-main__secondary-links {
  visibility: hidden;
  transition: visibility 0s linear 0.25s;
}

.nav-main__secondary-links li {
  padding: 0.175rem 0;
  transition: transform 0s linear 0.25s, visibility 0s linear 0.25s, opacity 0.25s cubic-bezier(0.6, 0, 0.14, 1);
  transform: translate3d(0, 4rem, 0);
  opacity: 0;
}

.nav-main__secondary-links li > a,
.nav-main__secondary-links li > .btn:not(.btn--single-icon) {
  display: block;
}

.nav-main__secondary-links li > a span,
.nav-main__secondary-links li > .btn:not(.btn--single-icon) span {
  position: relative;
}

.nav-main__secondary-links li > a span:before,
.nav-main__secondary-links li > .btn:not(.btn--single-icon) span:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -0.2rem;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  height: 1px;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.no-mobile.no-touch .nav-main__secondary-links li:hover > a span:before,
.no-mobile.no-touch .nav-main__secondary-links li:hover > .btn:not(.btn--single-icon) span:before,
body:not(.has-secondary-menu) .nav-main__secondary-links li.is-active > a span:before,
body:not(.has-secondary-menu) .nav-main__secondary-links li.is-active > .btn:not(.btn--single-icon) span:before, .nav-main__secondary-links li.has-secondary > a span:before,
.nav-main__secondary-links li.has-secondary > .btn:not(.btn--single-icon) span:before {
  transform: translateX(-50%) scaleX(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s, opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s;
}

.ie .nav-main__secondary-links li span:before,
.edge .nav-main__secondary-links li span:before {
  transition: none !important;
}

.has-secondary-menu .nav-main__secondary-links li {
  transition: transform 0.25s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.25s cubic-bezier(0.6, 0, 0.14, 1);
  transform: none;
  opacity: 1;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(0) {
  transition-delay: -0.03s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(1) {
  transition-delay: 0s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(2) {
  transition-delay: 0.03s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(3) {
  transition-delay: 0.06s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(4) {
  transition-delay: 0.09s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(5) {
  transition-delay: 0.12s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(6) {
  transition-delay: 0.15s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(7) {
  transition-delay: 0.18s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(8) {
  transition-delay: 0.21s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(9) {
  transition-delay: 0.24s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(10) {
  transition-delay: 0.27s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(11) {
  transition-delay: 0.3s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(12) {
  transition-delay: 0.33s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(13) {
  transition-delay: 0.36s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(14) {
  transition-delay: 0.39s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(15) {
  transition-delay: 0.42s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(16) {
  transition-delay: 0.45s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(17) {
  transition-delay: 0.48s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(18) {
  transition-delay: 0.51s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(19) {
  transition-delay: 0.54s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(20) {
  transition-delay: 0.57s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(21) {
  transition-delay: 0.6s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(22) {
  transition-delay: 0.63s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(23) {
  transition-delay: 0.66s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(24) {
  transition-delay: 0.69s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(25) {
  transition-delay: 0.72s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(26) {
  transition-delay: 0.75s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(27) {
  transition-delay: 0.78s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(28) {
  transition-delay: 0.81s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(29) {
  transition-delay: 0.84s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(30) {
  transition-delay: 0.87s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(31) {
  transition-delay: 0.9s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(32) {
  transition-delay: 0.93s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(33) {
  transition-delay: 0.96s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(34) {
  transition-delay: 0.99s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(35) {
  transition-delay: 1.02s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(36) {
  transition-delay: 1.05s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(37) {
  transition-delay: 1.08s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(38) {
  transition-delay: 1.11s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(39) {
  transition-delay: 1.14s;
}

.has-secondary-menu .nav-main__secondary-links li:nth-child(40) {
  transition-delay: 1.17s;
}

@media only screen and (min-width: 1000px) {
  .nav-main__secondary-links {
    left: 0;
    width: 100vw;
    background: #fff;
    padding: 2.5rem 2.5rem 6.4em 50vw;
    max-height: 95vw;
    overflow: hidden;
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  body:not(.has-secondary-menu) .nav-main__secondary-links {
    transform: translate3d(0, -8rem, 0);
    opacity: 0;
    transition: transform 0s linear 0.35s, opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s linear 0.35s;
  }
  .nav-main__secondary-links li {
    padding: 0;
    transform: translate3d(0, 4rem, 0);
  }
  .has-secondary-menu .nav-main__secondary-links li {
    transition: transform 0.6s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.6s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(0) {
    transition-delay: 0.08s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(1) {
    transition-delay: 0.1s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(2) {
    transition-delay: 0.12s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(3) {
    transition-delay: 0.14s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(4) {
    transition-delay: 0.16s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(5) {
    transition-delay: 0.18s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(6) {
    transition-delay: 0.2s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(7) {
    transition-delay: 0.22s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(8) {
    transition-delay: 0.24s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(9) {
    transition-delay: 0.26s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(10) {
    transition-delay: 0.28s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(11) {
    transition-delay: 0.3s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(12) {
    transition-delay: 0.32s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(13) {
    transition-delay: 0.34s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(14) {
    transition-delay: 0.36s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(15) {
    transition-delay: 0.38s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(16) {
    transition-delay: 0.4s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(17) {
    transition-delay: 0.42s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(18) {
    transition-delay: 0.44s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(19) {
    transition-delay: 0.46s;
  }
  .has-secondary-menu .nav-main__secondary-links li:nth-child(20) {
    transition-delay: 0.48s;
  }
  .nav-main__secondary-links li a {
    font-size: 2rem;
    line-height: 1.4;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-main__secondary-links {
    width: calc(50vw - 3rem);
  }
}

.nav-main__close-secondary {
  position: absolute;
  top: 0;
  right: -50vw;
  font-size: inherit;
  padding: 2em;
}

body:not(.has-secondary-menu) .nav-main__close-secondary {
  display: none;
}

@media only screen and (min-width: 600px) {
  .nav-main__close-secondary {
    padding: 2.5rem;
  }
}

.nav-main__contact {
  position: absolute;
  bottom: 2em;
  right: 2em;
  left: 2em;
  transition: transform 0.6s cubic-bezier(0.6, 0, 0.14, 1) 0.4s, opacity 0.6s cubic-bezier(0.6, 0, 0.14, 1) 0.4s;
}

.nav-main__contact > * {
  font-size: 1.6rem;
  line-height: 1.5;
}

.nav-main__contact a {
  display: inline-block;
}

@media only screen and (max-width: 599px) {
  body:not(.has-menu) .nav-main__contact,
  .has-secondary-menu .nav-main__contact {
    transition: transform 0.25s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.25s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s cubic-bezier(0.6, 0, 0.14, 1) 0.25s;
    opacity: 0;
    transform: translate3d(0, 1rem, 0);
    visibility: hidden;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-main__contact {
    width: calc(50vw - 3rem);
    bottom: 3rem;
    right: 3rem;
    left: 3rem;
  }
  .nav-main__contact > * {
    font-size: 2rem;
    line-height: 1.4;
  }
}

.nav-main__cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: -40vh;
  right: 0;
  background: rgba(231, 221, 204, 0.9);
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s cubic-bezier(0.6, 0, 0.14, 1) 0.35s;
}

.has-menu .nav-main__cover {
  transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1000px) {
  .has-menu .nav-main__cover {
    opacity: 0;
    visibility: hidden;
  }
  .has-secondary-menu .nav-main__cover {
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
    opacity: 1;
    visibility: visible;
  }
}

.header-intro {
  min-height: calc(4em + 5rem + 2em + 10rem);
  padding: calc(4em + 5rem) 2em 2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media only screen and (min-width: 600px) {
  .header-intro {
    padding: 2.5rem 2.5rem 2.5rem 50vw;
    height: 10rem;
    min-height: 0;
    margin-bottom: -10rem;
    flex-direction: row;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .header-intro {
    padding: 3rem 3rem 3rem 50vw;
  }
}

.header-intro__claim h2 {
  font-size: 1.6rem;
  line-height: 1.5;
  max-width: 25rem;
}

@media only screen and (max-width: 599px) {
  body:not(.page--homepage) .header-intro__claim {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .header-intro__claim {
    width: 45rem;
    z-index: 1;
    position: relative;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .header-intro__claim {
    width: 36rem;
  }
}

.header-intro__page-title h1 {
  font-size: 2.75rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .header-intro__page-title h1 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.simple-hero {
  padding: 2em;
  border-top: 1px solid #c4b088;
  background: #fff;
  position: relative;
}

.simple-hero h1,
.simple-hero h2,
.simple-hero h3 {
  font-size: 2.75rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .simple-hero h1,
  .simple-hero h2,
  .simple-hero h3 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.modal .simple-hero {
  border-top: none;
  padding-right: 7em;
}

@supports (position: sticky) {
  .modal .simple-hero {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.modal .simple-hero h1,
.modal .simple-hero h2,
.modal .simple-hero h3 {
  font-size: 2.375rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .modal .simple-hero h1,
  .modal .simple-hero h2,
  .modal .simple-hero h3 {
    font-size: 2.6rem;
    line-height: 1.35;
    letter-spacing: 0.015em;
  }
}

@media only screen and (max-width: 599px) {
  .header-intro.hidden-on-mobile + main .simple-hero {
    min-height: calc(4em + 5rem + 2em + 10rem);
    padding: calc(4em + 5rem) 2em 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-top: none;
  }
}

@media only screen and (min-width: 600px) {
  .simple-hero {
    border-top: none;
    padding: calc(2.5rem * 2 + 7rem + 5vh) 2.5rem 3.2em 50vw;
  }
  .modal .simple-hero {
    padding: 8rem calc(((50vw - 45rem - 2.5rem) / 2)) 3.2em 2.5rem;
  }
  .has-modal--rtl .modal .simple-hero {
    padding: 8rem 2.5rem 3.2em calc(((50vw - 45rem - 2.5rem) / 2));
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .simple-hero {
    padding: calc(3rem * 2 + 7rem + 5vh) 3rem 3.2em 50vw;
  }
  .modal .simple-hero {
    padding: 8rem calc(((50vw - 36rem - 3rem) / 2)) 3.2em 3rem;
  }
  .has-modal--rtl .modal .simple-hero {
    padding: 8rem 3rem 3.2em calc(((50vw - 36rem - 3rem) / 2));
  }
}

@media only screen and (min-width: 600px) {
  .simple-hero--l {
    min-height: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 4.8em;
  }
}

.content-section {
  background: #fff;
  position: relative;
  border-top: 1px solid #c4b088;
}

body:not(.page--metaPages) .simple-hero + .content-section {
  border-top: none;
}

.content-section .main-col {
  padding: 3.2em 2em 1.6em;
}

.content-section > .main-col:first-child {
  padding-top: 4.8em;
}

.content-section > .main-col:last-child {
  padding-bottom: 6.4em;
}

.content-section > .listing {
  padding: 6.4em 2em;
}

@media only screen and (min-width: 600px) {
  .simple-hero + .content-section {
    border-top: none;
  }
  .content-section .main-col {
    padding: 3.2em 2.5rem 1.6em 50vw;
  }
  .modal .content-section .main-col {
    padding: 3.2em calc(((50vw - 45rem - 2.5rem) / 2)) 1.6em 2.5rem;
  }
  .has-modal--rtl .modal .content-section .main-col {
    padding: 3.2em 2.5rem 1.6em calc(((50vw - 45rem - 2.5rem) / 2));
  }
  .content-section > .main-col:first-child {
    padding-top: 9em;
  }
  .content-section > .main-col:last-child {
    padding-bottom: 12.2em;
  }
  .content-section > .listing {
    padding: 6.4em 2.5rem 9em;
  }
  .content-section > .gallery:first-child {
    padding-top: 6.4em;
  }
  .content-section > .gallery:last-child {
    padding-bottom: 13.8em;
  }
  .content-section:not(.content-section--menu-cover):before, .content-section:not(.content-section--menu-cover):after {
    content: ' ';
    display: table;
  }
  .content-section:not(.content-section--menu-cover):after {
    clear: both;
  }
  .content-section:after {
    content: "";
    display: inline-block;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(231, 221, 204, 0.9);
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s cubic-bezier(0.6, 0, 0.14, 1) 0.35s;
    z-index: 110;
  }
  .content-section.is-active-cover:after {
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
    opacity: 1;
    visibility: visible;
  }
  .content-section.is-active-cover--still:after {
    z-index: 110;
  }
  @supports (position: sticky) {
    .content-section.content-section--menu-cover {
      position: relative;
    }
    .content-section.content-section--menu-cover:before {
      content: "";
      display: block;
      position: sticky;
      top: -1px;
      height: 8rem;
      background: #fff;
      z-index: 1;
    }
    .content-section.content-section--menu-cover > :first-child {
      margin-top: -8rem;
    }
  }
  .content-section.content-section--menu-cover .section-head {
    z-index: 1;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .content-section .main-col {
    padding: 3.2em 3rem 1.6em 50vw;
  }
  .modal .content-section .main-col {
    padding: 3.2em calc(((50vw - 36rem - 3rem) / 2)) 1.6em 3rem;
  }
  .has-modal--rtl .modal .content-section .main-col {
    padding: 3.2em 3rem 1.6em calc(((50vw - 36rem - 3rem) / 2));
  }
  .content-section > .listing {
    padding: 6.4em 3rem 9em;
  }
}

.section-head h3,
.section-head h4 {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (min-width: 600px) {
  .section-head {
    position: sticky;
    top: calc(2.5rem - 3.2em);
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .section-head {
    top: calc(3rem - 3.2em);
  }
}

.footer-main {
  background: #fff;
  position: relative;
}

.is-safari .footer-main {
  will-change: transform;
}

.contact-footer {
  border-top: 1px solid #c4b088;
  padding: 6.4em 2em;
  text-align: center;
}

.contact-footer > *:not(:first-child) {
  margin-top: 3.2em;
}

@media only screen and (min-width: 600px) {
  .contact-footer {
    padding: 9em 2.5rem;
  }
  .contact-footer > * {
    width: 25%;
  }
  .contact-footer > *:not(:first-child) {
    margin-top: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .contact-footer {
    padding: 9em 3rem;
  }
}

.contact-footer__head h4 {
  font-size: 2rem;
  line-height: 1.4;
}

@media only screen and (min-width: 600px) {
  .contact-footer__head {
    margin-top: 2.4rem;
  }
}

.newsletter-footer {
  border-top: 1px solid #c4b088;
  padding: 6.4em 2em;
}

.newsletter-footer .newsletter__form-container {
  max-width: 30rem;
  margin: auto;
}

.newsletter-footer .newsletter__form-container h2 {
  text-align: center;
  font-size: 2rem;
  line-height: 1.4;
}

@media only screen and (min-width: 600px) {
  .newsletter-footer .newsletter__form-container {
    max-width: 40rem;
  }
}

.newsletter-footer .newsletter__form-content {
  margin: auto;
  padding: 0 2rem;
}

.newsletter-footer .newsletter__form-content input:not([type='email']) + label {
  width: fit-content;
}

.nav-footer {
  display: flex;
  justify-content: center;
  background: #f5f1ea;
  padding: 1.6em 2em;
  min-height: 5em;
}

.nav-footer ul {
  display: flex;
  align-items: center;
}

.nav-footer ul li:not(:first-child) {
  margin-left: 1.6rem;
}

.nav-footer ul li > a,
.nav-footer ul li > .btn:not(.btn--single-icon) {
  display: block;
}

.nav-footer ul li > a span,
.nav-footer ul li > .btn:not(.btn--single-icon) span {
  position: relative;
}

.nav-footer ul li > a span:before,
.nav-footer ul li > .btn:not(.btn--single-icon) span:before {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -0.2rem;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  height: 1px;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1), opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.no-mobile.no-touch .nav-footer ul li:hover > a span:before,
.no-mobile.no-touch .nav-footer ul li:hover > .btn:not(.btn--single-icon) span:before,
body:not(.has-secondary-menu) .nav-footer ul li.is-active > a span:before,
body:not(.has-secondary-menu) .nav-footer ul li.is-active > .btn:not(.btn--single-icon) span:before, .nav-footer ul li.has-secondary > a span:before,
.nav-footer ul li.has-secondary > .btn:not(.btn--single-icon) span:before {
  transform: translateX(-50%) scaleX(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s, opacity 0.15s cubic-bezier(0.1, 0.6, 0.4, 1) 0.05s;
}

.ie .nav-footer ul li span:before,
.edge .nav-footer ul li span:before {
  transition: none !important;
}

.nav-footer ul a {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media only screen and (min-width: 600px) {
  .nav-footer {
    display: flex;
    justify-content: space-between;
    padding: 1.6em 2.5rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .nav-footer {
    padding: 1.6em 3rem;
  }
}

body.has-modal {
  height: 100%;
  overflow: hidden;
}

body.has-modal main > *:not(.is-active-cover--still),
body.has-modal .footer-main:not(.is-active-cover--still), body.has-modal--fading main > *:not(.is-active-cover--still),
body.has-modal--fading .footer-main:not(.is-active-cover--still) {
  z-index: -1;
}

.modal {
  position: relative;
  z-index: 200;
}

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transform-origin: center center;
  transform: translate3d(-20%, 0, 0);
  opacity: 0;
  visibility: hidden;
}

.has-modal--rtl .modal__wrapper {
  transform: translate3d(20%, 0, 0);
  transition: none;
  flex-direction: column-reverse;
}

.has-modal .modal__wrapper {
  transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  transform: none;
  opacity: 1;
  visibility: visible;
}

.has-modal--fading .modal__wrapper {
  transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s linear 0.35s;
}

.has-modal--fully-opened .modal__wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 599px) {
  body:not(.has-modal) .modal__wrapper {
    transform: translate3d(0, 50vh, 0);
  }
}

.modal__inner {
  background: #fff;
  min-height: 100vh;
  position: relative;
}

@media only screen and (min-width: 600px) {
  .modal__inner {
    width: calc(50vw + ((50vw - 45rem - 2.5rem) / 2));
  }
  .has-modal--rtl .modal__inner {
    float: right;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .modal__inner {
    width: calc(50vw + ((50vw - 36rem - 3rem) / 2));
  }
}

.modal__cover {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(231, 221, 204, 0.9);
  opacity: 0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s cubic-bezier(0.6, 0, 0.14, 1) 0.35s;
}

.has-modal .modal__cover {
  transition: opacity 0.25s cubic-bezier(0.6, 0, 0.14, 1);
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 600px) {
  .has-modal--btn-triggered .modal__cover {
    display: none;
  }
}

.modal__close-btn {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  cursor: pointer;
  padding: 2em;
  opacity: 0;
  visibility: hidden;
}

.has-modal .modal__close-btn {
  transition: opacity 1s cubic-bezier(0.6, 0, 0.14, 1) 0.35s;
  opacity: 1;
  visibility: visible;
}

.modal__close-btn .svg-ico--gol-close-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  .modal__close-btn {
    right: calc(50vw - ((50vw - 45rem - 2.5rem) / 2));
    padding: 2.5rem;
  }
  .modal__close-btn .svg-ico--gol-close {
    display: none;
  }
  .modal__close-btn .svg-ico--gol-close-desktop {
    display: block;
  }
  .has-modal--rtl .modal__close-btn {
    right: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .modal__close-btn {
    right: calc(50vw - ((50vw - 36rem - 3rem) / 2));
    padding: 3rem;
  }
}

.modal__close {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  display: none;
}

@supports (position: sticky) {
  .modal__close {
    height: 100vh;
    margin-bottom: -100vh;
    position: sticky;
  }
}

.has-modal .modal__close {
  display: block;
}

@supports (clip-path: inset(0 0 0 0)) {
  @media only screen and (min-width: 600px) {
    .ios .has-modal--fully-opened .header-main,
    .ios .has-modal--fully-opened .header-intro,
    .ios .has-modal--fully-opened main {
      clip-path: inset(0 0 0 calc(50vw + ((50vw - 45rem - 2.5rem) / 2)));
    }
    .ios .has-modal--rtl.has-modal--fully-opened .header-main,
    .ios .has-modal--rtl.has-modal--fully-opened .header-intro,
    .ios .has-modal--rtl.has-modal--fully-opened main {
      clip-path: inset(0 calc(50vw + ((50vw - 45rem - 2.5rem) / 2)) 0 0);
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .ios .has-modal--fully-opened .header-main,
    .ios .has-modal--fully-opened .header-intro,
    .ios .has-modal--fully-opened main {
      clip-path: inset(0 0 0 calc(50vw + ((50vw - 36rem - 3rem) / 2)));
    }
    .ios .has-modal--rtl.has-modal--fully-opened .header-main,
    .ios .has-modal--rtl.has-modal--fully-opened .header-intro,
    .ios .has-modal--rtl.has-modal--fully-opened main {
      clip-path: inset(0 calc(50vw + ((50vw - 36rem - 3rem) / 2)) 0 0);
    }
  }
}

.couple h1,
.couple h2 {
  font-size: 2.75rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .couple h1,
  .couple h2 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.couple .couple__img {
  padding: 4.8em calc(((100vw - 4em) / 6 + 2em)) 0 0;
}

.couple .couple__img a {
  display: block;
  height: 100%;
  width: 100%;
}

.couple .couple__img .media-box {
  padding-bottom: 110%;
  width: 100%;
  background: rgba(255, 255, 255, 0.25);
}

.couple.couple--passepartout .couple__img {
  padding: 4.8em calc(((100vw - 4em) / 6 + 2em));
}

.couple .couple__content {
  padding: 3.2em 2em 9em 2em;
  background: #fff;
  position: relative;
}

.couple.couple--rtl:not(.couple--passepartout) .couple__img {
  padding: 4.8em 0 0 calc(((100vw - 4em) / 6 + 2em));
}

.couple.couple--blank .media-box__title {
  color: #000;
}

@media only screen and (max-width: 599px) {
  .couple.couple--extendable .couple__img .media-box {
    position: relative;
  }
  .couple.couple--extendable .couple__img .media-box:after {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: svg-inline(icon--gol-extend);
    background-size: 3rem 3rem;
    background-position: center center;
    width: 3rem;
    height: 3rem;
    margin: 0.5rem;
  }
  .couple.couple--extendable .couple__content {
    padding: 3.2em 2.5rem 9em 2.5rem;
  }
  .couple.couple--extendable .couple__content > * {
    transition: opacity 2s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .couple.couple--extendable:not(.is-expanded) .couple__content {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
  }
  .couple.couple--extendable:not(.is-expanded) .couple__content > * {
    opacity: 0;
  }
}

@media only screen and (min-width: 600px) {
  .couple {
    min-height: 100vh;
    display: flex;
    position: relative;
    background: #fff;
  }
  .content-section + .couple {
    border-top: 1px solid #c4b088;
  }
  .couple > * {
    width: 50%;
  }
  .couple:after {
    content: "";
    display: inline-block;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(231, 221, 204, 0.9);
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1), visibility 0s cubic-bezier(0.6, 0, 0.14, 1) 0.35s;
    z-index: 110;
  }
  .couple.is-active-cover:after {
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
    opacity: 1;
    visibility: visible;
  }
  .couple.is-active-cover--still:after {
    z-index: 110;
  }
  .couple .couple__img {
    display: flex;
    padding: calc(2.5rem * 2 + 7rem + 5vh) 0 0 calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem));
  }
  @supports (position: sticky) {
    .couple .couple__img {
      position: sticky;
      top: 0;
      height: 100vh;
      margin-bottom: -100vh;
      will-change: transform;
    }
  }
  .couple .couple__img .media-box {
    padding-bottom: 0;
    height: 100%;
    max-height: 60vw;
  }
  @supports (--css: variable) {
    .couple .couple__img .media-box {
      --img-opacity: 1;
      opacity: var(--img-opacity);
      will-change: opacity;
    }
  }
  .couple.couple--passepartout .couple__img {
    padding: calc(2.5rem * 2 + 7rem + 5vh) calc(((50vw - 45rem - 2.5rem) / 2)) 9em calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem));
  }
  .couple.couple--passepartout .couple__img .media-box {
    max-height: 36vw;
  }
}

@media only screen and (min-width: 600px) and (orientation: portrait) {
  .couple.couple--passepartout .couple__img .media-box {
    max-height: 54vw;
  }
}

@media only screen and (min-width: 600px) {
  .couple.couple--top-img .couple__img {
    padding-top: 0;
    padding-bottom: calc(2.5rem * 2 + 7rem + 5vh);
  }
  .couple .couple__content {
    padding: 0 2.5rem 6.4em 0;
  }
  .couple .couple__content .main-col {
    padding-top: calc(2.5rem * 2 + 7rem + 5vh + 4.8em);
  }
  .ios .couple:first-child .couple__content {
    padding-bottom: 12.2em;
  }
  .couple .main-col {
    height: 100%;
  }
  .couple.couple--rtl {
    flex-direction: row-reverse;
  }
  .couple.couple--rtl:not(.couple--passepartout) .couple__img {
    padding: calc(2.5rem * 2 + 7rem + 5vh) calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem)) 0 0;
  }
  .couple.couple--rtl.couple--passepartout .couple__img {
    padding: calc(2.5rem * 2 + 7rem + 5vh) calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem)) 9em calc(((50vw - 45rem - 2.5rem) / 2));
  }
  .couple.couple--rtl.couple--top-img .couple__img {
    padding-top: 0;
    padding-bottom: calc(2.5rem * 2 + 7rem + 5vh);
  }
  .couple.couple--rtl .couple__content {
    padding: 0 0 6.4em 2.5rem;
  }
  @supports (position: sticky) {
    .couple.couple--rtl .couple__content {
      position: relative;
    }
    .couple.couple--rtl .couple__content:before {
      content: "";
      display: block;
      position: sticky;
      top: -1px;
      height: 8rem;
      background: #fff;
      z-index: 1;
    }
    .couple.couple--rtl .couple__content > :first-child {
      margin-top: -8rem;
    }
  }
  .couple:first-child .couple__content a:first-child,
  .couple:first-child .couple__content .text-big:first-child {
    flex: 1 1 auto;
    margin-bottom: 3.2em;
  }
  .couple:first-child .couple__content a:first-child {
    padding-top: 3.2em;
  }
  .simple-hero + .couple:not(.couple--rtl) {
    background: transparent;
    margin-top: calc((2.5rem * 2 + 7rem + 5vh) * -1);
  }
  .simple-hero + .couple:not(.couple--rtl) .couple__img:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -40rem;
    height: 40rem;
    left: 0;
    right: 0;
    background: #e7ddcc;
  }
  .simple-hero + .couple:not(.couple--rtl) .couple__img.section-fond-2:before {
    background: #ede7d9;
  }
  .simple-hero + .couple:not(.couple--rtl) .couple__img.section-fond-3:before {
    background: #f5f1ea;
  }
  .simple-hero + .couple:not(.couple--rtl) .couple__content {
    margin-top: calc(2.5rem * 2 + 7rem + 5vh);
    border-top: 1px solid #c4b088;
    background: #fff;
  }
  .couple.couple--extendable .couple__content {
    padding-bottom: 12.2em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .couple .couple__img {
    padding: calc(3rem * 2 + 7rem + 5vh) 0 0 calc(((50vw - 36rem - 3rem) / 2 + 3rem));
  }
  .couple.couple--passepartout .couple__img {
    padding: calc(3rem * 2 + 7rem + 5vh) calc(((50vw - 36rem - 3rem) / 2)) 9em calc(((50vw - 36rem - 3rem) / 2 + 3rem));
  }
  .couple .couple__content {
    padding: 0 3rem 6.4em 0;
  }
  .couple .couple__content .main-col {
    padding-top: calc(3rem * 2 + 7rem + 5vh + 4.8em);
  }
  .couple.couple--rtl:not(.couple--passepartout) .couple__img {
    padding: calc(3rem * 2 + 7rem + 5vh) calc(((50vw - 36rem - 3rem) / 2 + 3rem)) 0 0;
  }
  .couple.couple--rtl.couple--passepartout .couple__img {
    padding: calc(3rem * 2 + 7rem + 5vh) calc(((50vw - 36rem - 3rem) / 2 + 3rem)) 9em calc(((50vw - 36rem - 3rem) / 2));
  }
  .couple.couple--rtl .couple__content {
    padding: 0 0 6.4em 3rem;
  }
  @supports (position: sticky) {
    .couple.couple--rtl .couple__content {
      position: relative;
    }
    .couple.couple--rtl .couple__content:before {
      content: "";
      display: block;
      position: sticky;
      top: -1px;
      height: 8rem;
      background: #fff;
      z-index: 1;
    }
    .couple.couple--rtl .couple__content > :first-child {
      margin-top: -8rem;
    }
  }
  .simple-hero + .couple:not(.couple--rtl) {
    margin-top: calc((3rem * 2 + 7rem + 5vh) * -1);
  }
  .simple-hero + .couple:not(.couple--rtl) .couple__content {
    margin-top: calc(3rem * 2 + 7rem + 5vh);
  }
}

@media only screen and (orientation: portrait) {
  .couple {
    min-height: 80vw;
  }
}

[data-action="expandable:toggle"] {
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .teaser-combo {
    padding: 9em calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem)) 6.4em calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem));
    display: flex;
    flex-wrap: wrap;
  }
  .teaser-combo > * {
    width: 50%;
  }
  .teaser-combo > *:nth-child(even) {
    margin-top: 9em;
  }
  .teaser-combo + .teaser-combo {
    padding-top: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .teaser-combo {
    padding: 9em calc(((50vw - 36rem - 3rem) / 2 + 3rem)) 6.4em calc(((50vw - 36rem - 3rem) / 2 + 3rem));
  }
}

.teaser-combo__teaser .media-box {
  padding-bottom: 100%;
  background: #f5f1ea;
}

.teaser-combo__teaser:nth-child(even) .media-box {
  background: #f7f4ef;
}

.teaser-combo__teaser-content {
  padding: 3.2em 2em 6.4em 2em;
}

.teaser-combo__teaser-content h3 {
  font-size: 2.75rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .teaser-combo__teaser-content h3 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

@media only screen and (min-width: 600px) {
  .teaser-combo__teaser-content {
    padding: 3.2em calc(((50vw - 45rem - 2.5rem) / 2)) 6.4em 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .teaser-combo__teaser-content {
    padding: 3.2em calc(((50vw - 36rem - 3rem) / 2)) 6.4em 0;
  }
}

/* Gallery Layout

Starting with a wider / smaller
then smaller / wider in the next row
|  1b  2    |
|  3   4b   |
|  5b  6    |
|  7   8b   |
|  9b  10   |
|  11  12b  |
|  13b 14   |
|  15  16b  |
| ...

Variation right-to-left switches sizes order

// Sizes mobile on a 6-column grid: 2.5/6 and 3/6
// Sizes big image: 5/6
// Same for desktop modals

// Sizes desktop on a 10-column grid: 4/10 and 5/10

*/
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2em;
}

.gallery > * {
  margin-top: 3.2em;
  width: 41.66667%;
}

.gallery.gallery--ltr > :nth-child(4n-3),
.gallery.gallery--ltr > :nth-child(4n) {
  width: 50%;
}

.gallery.gallery--rtl > :nth-child(4n-2),
.gallery.gallery--rtl > :nth-child(4n+3) {
  width: 50%;
}

.gallery > :first-child:last-child {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.gallery > :first-child:last-child figcaption,
.gallery > :first-child:last-child .media-box {
  width: 83.33333%;
}

.gallery.gallery--rtl > :first-child:last-child figcaption,
.gallery.gallery--rtl > :first-child:last-child .media-box {
  align-self: flex-end;
}

.gallery .media-box {
  background: #f0f0f0;
}

.gallery:last-child {
  padding-bottom: 6.4em;
}

.gallery + .main-col {
  padding-top: 4.8em;
}

@media only screen and (min-width: 600px) {
  .gallery {
    padding: 0 calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem));
  }
  .gallery > * {
    margin-top: 6.4em;
  }
  .modal .gallery {
    padding: 0 calc(((50vw - 45rem - 2.5rem) / 2)) 0 2.5rem;
  }
  .has-modal--rtl .modal .gallery {
    padding: 0 2.5rem 0 calc(((50vw - 45rem - 2.5rem) / 2));
  }
  main .gallery > * {
    width: 40%;
  }
  main .gallery.gallery--ltr > :nth-child(4n-3),
  main .gallery.gallery--ltr > :nth-child(4n) {
    width: 50%;
  }
  main .gallery.gallery--rtl > :nth-child(4n-2),
  main .gallery.gallery--rtl > :nth-child(4n+3) {
    width: 50%;
  }
  main .gallery > :first-child:last-child figcaption,
  main .gallery > :first-child:last-child .media-box {
    width: 70%;
  }
  .gallery:last-child {
    padding-bottom: 9em;
  }
  .gallery + .main-col {
    padding-top: 6.4em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .gallery {
    padding: 0 calc(((50vw - 36rem - 3rem) / 2 + 3rem));
  }
  .modal .gallery {
    padding: 0 calc(((50vw - 36rem - 3rem) / 2)) 0 3rem;
  }
  .has-modal--rtl .modal .gallery {
    padding: 0 3rem 0 calc(((50vw - 36rem - 3rem) / 2));
  }
}

figure p {
  margin-top: 1rem;
  font-size: 1.6rem;
  line-height: 1.5;
}

figcaption {
  margin-bottom: 2rem;
}

@media only screen and (min-width: 600px) {
  figcaption {
    margin-bottom: 4rem;
  }
}

.brand-listing a {
  font-size: 2.75rem;
  line-height: 1.3;
  display: block;
}

@media only screen and (min-width: 600px) {
  .brand-listing a {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.brand-images__img {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1) 0.25s, visibility 0s linear 0.5s;
}

.brand-images__img.is-active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.brand-images-title {
  transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1) 0.1s;
}

.brand-images-title.has-active-child {
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.1, 0.6, 0.4, 1);
}

.slideshow {
  width: 100%;
  position: relative;
  transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
  opacity: 1;
  margin-top: -4.8em;
}

.slideshow.slideshow:not(.flickity-enabled) {
  white-space: nowrap;
  overflow: hidden;
}

.slideshow.slideshow:not(.flickity-enabled) > * {
  display: inline-block;
  float: none;
  white-space: normal;
  opacity: 0;
}

.slideshow.slideshow:not(.flickity-enabled) > *:first-child {
  opacity: 1;
}

@media only screen and (min-width: 600px) {
  .slideshow.slideshow:not(.flickity-enabled).slideshow--small > * {
    position: relative;
  }
  .slideshow.slideshow:not(.flickity-enabled).slideshow--small > * .slideshow__slide-header {
    position: absolute;
    top: 4.8em;
    right: 0;
  }
  .slideshow.slideshow:not(.flickity-enabled).slideshow--small > *:nth-child(2) {
    opacity: 1;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .slideshow.slideshow:not(.flickity-enabled).slideshow--small > * .slideshow__slide-header {
    position: relative;
    top: auto;
    right: auto;
  }
}

.slideshow:after {
  content: 'flickity';
  display: table;
  visibility: hidden;
  height: 0;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  clear: both;
}

.slideshow:before {
  content: ' ';
  display: table;
}

.slideshow.flickity-enabled.is-draggable .media-box {
  cursor: grab;
}

.slideshow.flickity-enabled.is-draggable .is-pointer-down .media-box {
  cursor: grabbing;
}

.slideshow.slideshow--small {
  padding: 0 2em;
  overflow: hidden;
}

.slideshow.slideshow--small .flickity-viewport {
  overflow: visible;
}

@media only screen and (min-width: 600px) {
  .slideshow {
    margin-top: 9em;
  }
  .is-active-cover--still .slideshow {
    z-index: 120;
  }
  .slideshow.slideshow--small {
    margin-top: 3.2em;
    padding: 0 calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem)) 9em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .slideshow.slideshow--small {
    padding: 0 calc(((50vw - 36rem - 3rem) / 2 + 3rem)) 9em;
  }
}

.slideshow__slide {
  width: 100vw;
  padding: 4.8em 2em 6.4em;
}

.slideshow__slide:nth-child(1) {
  z-index: 30;
}

.slideshow__slide:nth-child(2) {
  z-index: 29;
}

.slideshow__slide:nth-child(3) {
  z-index: 28;
}

.slideshow__slide:nth-child(4) {
  z-index: 27;
}

.slideshow__slide:nth-child(5) {
  z-index: 26;
}

.slideshow__slide:nth-child(6) {
  z-index: 25;
}

.slideshow__slide:nth-child(7) {
  z-index: 24;
}

.slideshow__slide:nth-child(8) {
  z-index: 23;
}

.slideshow__slide:nth-child(9) {
  z-index: 22;
}

.slideshow__slide:nth-child(10) {
  z-index: 21;
}

.slideshow__slide:nth-child(11) {
  z-index: 20;
}

.slideshow__slide:nth-child(12) {
  z-index: 19;
}

.slideshow__slide:nth-child(13) {
  z-index: 18;
}

.slideshow__slide:nth-child(14) {
  z-index: 17;
}

.slideshow__slide:nth-child(15) {
  z-index: 16;
}

.slideshow__slide:nth-child(16) {
  z-index: 15;
}

.slideshow__slide:nth-child(17) {
  z-index: 14;
}

.slideshow__slide:nth-child(18) {
  z-index: 13;
}

.slideshow__slide:nth-child(19) {
  z-index: 12;
}

.slideshow__slide:nth-child(20) {
  z-index: 11;
}

.slideshow__slide:nth-child(21) {
  z-index: 10;
}

.slideshow__slide:nth-child(22) {
  z-index: 9;
}

.slideshow__slide:nth-child(23) {
  z-index: 8;
}

.slideshow__slide:nth-child(24) {
  z-index: 7;
}

.slideshow__slide:nth-child(25) {
  z-index: 6;
}

.slideshow__slide:nth-child(26) {
  z-index: 5;
}

.slideshow__slide:nth-child(27) {
  z-index: 4;
}

.slideshow__slide:nth-child(28) {
  z-index: 3;
}

.slideshow__slide:nth-child(29) {
  z-index: 2;
}

.slideshow__slide:nth-child(30) {
  z-index: 1;
}

.slideshow--small .slideshow__slide {
  width: calc((100vw - 4em) / 6 * 5);
  display: flex;
  flex-direction: column-reverse;
  padding: 4.8em 0 6.4em;
}

.slideshow--small.flickity-enabled .slideshow__slide {
  min-height: 100%;
}

@media only screen and (min-width: 600px) {
  .slideshow__slide {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    padding: 0 calc(((50vw - 45rem - 2.5rem) / 2 + 2.5rem));
  }
  .slideshow--small .slideshow__slide {
    width: calc((100vw - 5rem) / 12 * 5);
    flex-direction: row;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .slideshow__slide {
    padding: 0 calc(((50vw - 36rem - 3rem) / 2 + 3rem));
  }
  .slideshow--small .slideshow__slide {
    display: block;
    padding-right: 9em;
  }
}

.slideshow__slide-img {
  width: calc((100vw - 4em) / 6 * 5);
  height: calc((100vw - 4em) / 6 * 5);
}

@supports (--css: variable) {
  .slideshow__slide-img {
    --img-offset: 0;
    --img-scale: 1;
    transform: translateX(var(--img-offset)) scale(var(--img-scale));
    transform-origin: 50% 50%;
  }
  .slideshow__slide:nth-child(2) .slideshow__slide-img {
    --img-offset: -40vw;
    --img-scale: 0.875;
  }
}

.slideshow__slide-img .media-box {
  height: 100%;
}

.slideshow__slide-img .media-box__title {
  color: inherit;
  text-align: right;
  transform: none;
  top: auto;
  bottom: 0;
  padding: 0.6rem 1rem;
}

.slideshow__slide-img .media-box__title h5 {
  font-size: 1.375rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
}

@media only screen and (min-width: 600px) {
  .slideshow__slide-img .media-box__title h5 {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
}

.slideshow__slide-img:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 3rem;
  left: 3rem;
  right: 0;
  bottom: 3rem;
  box-shadow: 0 0 5rem rgba(0, 0, 0, 0.15);
}

@supports (--css: variable) {
  .slideshow--small .slideshow__slide:nth-child(2) .slideshow__slide-img {
    --img-offset: -40vw;
    --img-scale: 1;
  }
}

.slideshow--small .slideshow__slide-img {
  width: calc((100vw - 4em) / 6 * 5);
  height: calc((100vw - 4em) / 6 * 5.5);
}

.slideshow--small .slideshow__slide-img:before {
  display: none;
}

@media only screen and (min-width: 600px) {
  .slideshow__slide-img {
    width: 50%;
    height: auto;
    z-index: 2;
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  @supports (--css: variable) {
    .slideshow__slide:nth-child(2) .slideshow__slide-img {
      --img-offset: -74vw;
      --img-scale: 0.8;
    }
  }
  .slideshow__slide-img .media-box {
    height: 0;
    padding-bottom: 100%;
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1);
    transform-origin: center center;
  }
  .slideshow__slide-img .media-box__title {
    text-align: left;
    padding: 2.4rem 3rem;
    opacity: 0.5;
    transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1);
  }
  .is-selected .slideshow__slide-img .media-box__title {
    opacity: 1;
  }
  .slideshow__slide-img .media-box__title h5 {
    font-size: 1.375rem;
    letter-spacing: 0.025em;
    line-height: 1.5;
  }
}

@media only screen and (min-width: 600px) and (min-width: 600px) {
  .slideshow__slide-img .media-box__title h5 {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
}

@media only screen and (min-width: 600px) {
  .is-active-trigger .slideshow__slide-img {
    transform: translate3d(calc(((50vw - 45rem - 2.5rem) / 2) - ((50vw - 2.5rem) * 0.1)), 0, 0);
  }
  .is-active-trigger .slideshow__slide-img:before,
  .is-active-trigger .slideshow__slide-img .media-box {
    transform: scale(0.8);
  }
  .is-active-trigger .slideshow__slide-img .media-box__title {
    transition: opacity 0.35s cubic-bezier(0.1, 0.6, 0.4, 1);
    opacity: 0;
  }
  .is-active-trigger--still .slideshow__slide-img {
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .is-active-trigger + .slideshow__slide .slideshow__slide-img {
    opacity: 0.12;
  }
  .slideshow__slide-img:before {
    opacity: 0;
    transform-origin: center center;
    transition: opacity 1s cubic-bezier(0.1, 0.6, 0.4, 1), transform 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .is-selected:not(:last-child) .slideshow__slide-img:before {
    opacity: 1;
  }
  .slideshow--small .slideshow__slide-img {
    padding-right: 1.5rem;
    width: 50%;
    height: auto;
    transform: none;
  }
  .slideshow--small .slideshow__slide-img .media-box {
    height: 0;
    padding-bottom: 120%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .is-active-trigger .slideshow__slide-img {
    transform: translate3d(calc(((50vw - 36rem - 3rem) / 2) - ((50vw - 3rem) * 0.1)), 0, 0);
  }
  .slideshow--small .slideshow__slide-img {
    width: 100%;
    padding-right: 0;
  }
}

.slideshow__slide-header {
  padding-top: 3.2em;
}

.slideshow__slide-header h2,
.slideshow__slide-header h3 {
  font-size: 2.75rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .slideshow__slide-header h2,
  .slideshow__slide-header h3 {
    font-size: 3.6rem;
    line-height: 1.25;
    letter-spacing: 0.015em;
  }
}

.slideshow--small .slideshow__slide-header {
  padding-top: 1.6em;
  padding-bottom: 3.2em;
  padding-right: 3rem;
  flex: 1 1 auto;
}

.slideshow--small .slideshow__slide-header h2,
.slideshow--small .slideshow__slide-header h3 {
  font-size: 2rem;
  line-height: 1.4;
}

.slideshow--small .slideshow__slide-header .text-link {
  display: none;
}

@media only screen and (min-width: 600px) {
  .slideshow__slide-header {
    width: 50%;
    padding: 6.4em calc(((50vw - 45rem - 2.5rem) / 2)) 0 0;
  }
  .slideshow--small .slideshow__slide-header {
    padding: 3.2em 5rem 1.6em 1.5rem;
    flex: 0 0 auto;
    position: relative;
    overflow: hidden;
  }
  .slideshow--small .slideshow__slide-header .text-link {
    display: block;
  }
  .slideshow--small .slideshow__slide-header:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 3rem;
    width: 2rem;
    height: 2rem;
    border-top: 1px solid #c4b088;
    border-right: 1px solid #c4b088;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .slideshow__slide-header {
    padding: 6.4em calc(((50vw - 36rem - 3rem) / 2)) 0 0;
  }
  .slideshow--small .slideshow__slide-header {
    width: 100%;
    padding: 1.6em 0 0 0;
  }
  .slideshow--small .slideshow__slide-header:before {
    display: none;
  }
}

.newsletter .mce-submit {
  display: flex;
  justify-content: center;
}

.newsletter__headline h2 {
  font-size: 2.375rem;
  line-height: 1.3;
}

@media only screen and (min-width: 600px) {
  .newsletter__headline h2 {
    font-size: 2.6rem;
    line-height: 1.35;
    letter-spacing: 0.015em;
  }
}

.newsletter__form-wrapper,
.newsletter__form-inner {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.newsletter__form {
  flex-grow: 1;
  margin-top: 3.2em;
}

.newsletter__form label,
.newsletter__form input {
  transition: all 0.125s cubic-bezier(0.1, 0.6, 0.4, 1);
  touch-action: manipulation;
  font-size: 1.6rem;
  line-height: 1.5;
}

.newsletter__form label {
  order: 1;
  color: #9e7f41;
  transform: translate(0, 0) scale(0.85);
  font-style: italic;
  transform-origin: left bottom;
}

.newsletter__form .field {
  display: flex;
  flex-flow: column-reverse;
  margin-bottom: 0.5rem;
}

.newsletter__form input {
  order: 1;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #9e7f41;
  padding: 0.4rem 0 0.8rem;
  font-family: inherit;
  -webkit-appearance: none;
}

.newsletter__form input::placeholder {
  color: transparent;
}

.newsletter__form input:focus {
  outline: 0;
}

.newsletter__form input:focus::placeholder {
  color: #8c8c8c;
}

.newsletter__form input[type="email"] + label {
  display: flex;
  justify-content: space-between;
}

.newsletter__form input:placeholder-shown:not(:focus) + label {
  cursor: pointer;
  transform: translate(0, calc(100% + 0.4rem));
}

.show-newsletter-names .newsletter__fields label {
  transform: translate(0, 0) scale(0.8);
  transform-origin: left bottom;
}

.show-newsletter-names .newsletter__fields label .newsletter__email-label--arrow {
  display: none;
}

.response:not(:empty) ~ .newsletter__fields {
  display: none;
}

.newsletter__names {
  display: none;
}

.show-newsletter-names .newsletter__names {
  display: block;
}

.newsletter__info {
  margin-top: auto;
  padding-top: 3rem;
}

.newsletter__info .secondary-text {
  display: block;
}

.show-newsletter-names .newsletter__info .secondary-text {
  display: none;
}

.response:not(:empty) ~ .newsletter__info {
  display: none;
}

.newsletter__legal-info {
  color: #8c8c8c;
  display: none;
  font-size: 1.375rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
}

.show-newsletter-names .newsletter__legal-info {
  display: block;
}

.newsletter__button {
  display: none;
  margin-top: 1.6rem;
}

.show-newsletter-names .newsletter__button {
  display: block;
}

.response:not(:empty) ~ .newsletter__button {
  display: none;
}

div.mce_inline_error {
  font-size: 1.375rem;
  letter-spacing: 0.025em;
  line-height: 1.5;
  font-weight: inherit !important;
  order: 0;
  margin: 0.5rem 0 0 0 !important;
  background: none !important;
  color: red !important;
  padding: 0 !important;
}

@media only screen and (min-width: 600px) {
  div.mce_inline_error {
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.02em;
  }
}

div.response {
  display: none !important;
}

div.custom-response {
  display: none;
  color: #9e7f41;
  text-align: center;
}

.response:not(:empty) ~ div.custom-response {
  display: block;
}

.product__main-img .media-box {
  padding-bottom: 100%;
}

@media only screen and (min-width: 600px) {
  .product__main-img {
    margin-bottom: -50vw;
  }
  .product__main-img .media-box {
    padding-bottom: 0;
    height: 50vw;
    width: 50vw;
  }
  .product__main-img + * {
    min-height: 50vw;
  }
  .modal .product__main-img {
    margin-bottom: 0;
    margin-left: 2em;
  }
  .modal .product__main-img + * {
    min-height: auto;
  }
  .has-modal--btn-triggered .product__main-img {
    margin-top: -3.2em;
  }
  .has-modal--btn-triggered .product__main-img .media-box {
    display: none;
  }
}

.product__title h1,
.product__title h2,
.product__title h3,
.product__title h4 {
  display: inline;
}

.product__title > :first-child:not(:last-child):after {
  content: " · ";
  white-space: pre;
}

.product-group {
  width: 66.66667%;
  margin: 0 auto;
}

@media only screen and (min-width: 600px) {
  .product-group {
    width: 45rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .product-group {
    width: 36rem;
  }
}

@media only screen and (min-width: 600px) {
  [data-modal] .media-box:after {
    position: absolute;
    content: "Schließen";
    top: 0;
    right: 0;
    padding: 2.4rem 3rem;
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: "Marr Sans Italic", Arial, sans-serif;
    font-style: italic;
    color: #9e7f41;
    opacity: 0;
    transition: opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .couple--rtl [data-modal] .media-box:after {
    left: 0;
    right: auto;
  }
  [data-modal].is-active-trigger .media-box:after {
    opacity: 1;
  }
}

.main-col.product-group-intro {
  height: auto;
}

.product-group__teaser {
  position: relative;
  display: block;
  margin-top: 4.8em;
}

.product-group__teaser:first-child {
  margin-top: 3.2em;
}

.product-group__teaser .media-box {
  padding-bottom: 100%;
}

.product-group__teaser .product__title {
  margin-top: 1.6rem;
}

.product-group__teaser .product__title span {
  color: #9e7f41;
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: "Marr Sans Italic", Arial, sans-serif;
  font-style: italic;
  display: block;
}

@media only screen and (min-width: 600px) {
  .product-group__teaser {
    margin-top: 4.8em;
  }
  .product-group__teaser .media-box {
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1);
    transform-origin: center center;
    transform: scale(0.77);
  }
  .product-group__teaser .product__title {
    margin: -3.4rem auto 0;
    width: 34rem;
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .product-group__teaser:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    transform: scale(0.5);
    box-shadow: 0 0 10rem rgba(122, 98, 58, 0.15);
    opacity: 0;
    transition: transform 0.35s cubic-bezier(0.6, 0, 0.14, 1), opacity 0.35s cubic-bezier(0.6, 0, 0.14, 1);
  }
  .product-group__teaser.is-active-trigger .media-box {
    transform: none;
  }
  .product-group__teaser.is-active-trigger .media-box:after {
    opacity: 1;
  }
  .product-group__teaser.is-active-trigger .product__title {
    transform: translate3d(0, 50%, 0);
    opacity: 0;
  }
  .product-group__teaser.is-active-trigger:before {
    opacity: 1;
    transform: none;
  }
  .product-group__teaser.is-active-trigger--still {
    z-index: 120;
  }
  .product-group__teaser:first-child {
    margin-top: calc((100vh - 34rem) / 2);
  }
  .simple-hero + .couple:not(.couple--rtl) .product-group__teaser:first-child {
    margin-top: calc(2.5rem * 2 + 7rem + 5vh);
  }
  .product-group-intro + .product-group .product-group__teaser:first-child {
    margin-top: 4.8em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .product-group__teaser {
    margin-top: 4.8em;
  }
  .product-group__teaser .product__title {
    width: 28rem;
  }
}

/// Some teaser
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.teaser-combo {
    @include respond-to(decent) {
        padding: $v-space--xxl main-col-padding(decent, true) $v-space--xl main-col-padding(decent, true);
        display: flex;
        flex-wrap: wrap;

        > * {
            width: 50%;
        }

        > *:nth-child(even) {
            margin-top: $v-space--xxl;
        }

        & + & {
            padding-top: 0;
        }
    }

    @include respond-to(grid-m) {
        padding: $v-space--xxl main-col-padding(grid-m, true) $v-space--xl main-col-padding(grid-m, true);
    }
}

.teaser-combo__teaser {
    .media-box {
        padding-bottom: 100%;
        background: $background--3;
    }

    &:nth-child(even) {
        .media-box {
            background: lighten($background--3, 1.5);
        }
    }
}

.teaser-combo__teaser-content {
    padding: $v-space--m body-padding() $v-space--xl body-padding();

    h3 {
        @include text--xl();
    }

    @include respond-to(decent) {
        padding: $v-space--m main-col-padding(decent) $v-space--xl 0;
    }

    @include respond-to(grid-m) {
        padding: $v-space--m main-col-padding(grid-m) $v-space--xl 0;
    }
}

/// Basic List Definitions
/// @group Lists
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

ul,
ol,
li,
dl,
dt,
dd {
    list-style: none;
    margin: 0;
    padding: 0;
    @include text--m;
}

.listing {
    > * {
        text-align: center;
        margin-top: 4rem;

        h4 ~ *:not(.text-link) {
            @include text--m;
        }

        .text-link {
            margin-top: 1rem;
        }

        a,
        data {
            display: block;
        }
    }

    @include respond-to(mobile) {
        &:first-child {
            > :first-child {
                margin-top: 0;
            }
        }
    }

    @include respond-to(decent) {
        display: flex;
        justify-content: space-between;

        > * {
            width: 50%;
            padding: 0 0.5rem;
        }
    }
}

.listing__social {
    // style for 3 items
    a {
        display: inline-block;
        padding: 0 0.4rem;
    }

    h4 + a {
        display: block;
    }

    > :nth-child(4) {
        padding-left: 0;

        &:before {
            content:  " · ";
        }
    }
}

dl {
    .main-col & {
        text-align: center;

        &:not(:first-child) {
            margin-top: 4rem;
        }

        @include respond-to(decent) {
            .text-big + & {
                margin-top: 0;
            }

            max-width: $product-col-width + 4rem;
        }

        @include respond-to(grid-m) {
            max-width: $product-col-width--m + 4rem;
        }
    }
}

dt {
    @include text--s;

    .main-col & {
        &:not(:first-child) {
            margin-top: 4rem;
        }
    }
}

ul {
    .main-col & {
        &:not(:first-child) {
            margin-top: 2rem;
        }

        @include respond-to(decent) {
            .text-big + & {
                margin-top: 0;
            }
        }
    }
}

li:not([data-brand-trigger]) {
    .main-col & {
        list-style-type: circle;
        margin-left: 3rem;

        p {
            display: inline-block;
        }
    }
}

/// Functions
/// @group Functions
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Strips the unit from a size statement
///
/// @group humans-machines
/// @param {number} $value - The number to strip
/// @return {number} - The stripped value
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

/// Replace `$search` with `$replace` in `$string`
///
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/// Returns a setting for the given key and grid size
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
/// @param {string} $key [columns] - The key of the setting
/// @return {mixed} - The value for the key and size
@function grid-setting($size: grid-s, $key: columns) {
    $value: 0;

    @if map-has-key($grid-config, $size) {
        $config: map-get($grid-config, $size);

        @if map-has-key($config, $key) {
            $value: map-get($config, $key);
        } @else {
            @warn 'Ooooops ... Could not find setting `#{$key}` in config for grid size `#{$size}`!';
        }
    } @else {
        @warn 'Ooooops ... `#{$size}` grid size is not defined!';
    }

    @return $value;
}

/// Returns the width of a grid cell for the given cell count and breakpoint
///
/// @group humans-machines
/// @param {number} $cell-count [1] - The cell count
/// @param {string} $size [grid-s] - The grid size
@function grid-width($cell-count: 1, $size: grid-s) {

    // Fetch settings
    $columns: grid-setting($size, columns);
    $padding: grid-setting($size, body-padding);

    @return calc((100vw - #{$padding * 2}) / #{$columns} * #{$cell-count});
}

/// Returns the padding of the main col
///
/// @group humans-machines
/// @param {number} $cell-count [1] - The cell count
/// @param {string} $size [grid-s] - The grid size
@function main-col-padding($size: grid-s, $extra-padding: false, $raw: false) {
    $value: 0;

    $columns: grid-setting($size, columns);
    $padding: grid-setting($size, body-padding);
    $main-col: grid-setting($size, main-col-width);

    // Mobile padding
    @if ($size == "grid-s") {
        $value: '((100vw - #{$padding * 2}) / #{$columns} + #{$padding})';

    // Desktop padding
    } @else {
        @if $extra-padding {
            $value: '((50vw - #{$main-col} - #{$padding}) / 2 + #{$padding})';
        } @else {
            $value: '((50vw - #{$main-col} - #{$padding}) / 2)';
        }
    }

    @if $raw {
        @return $value;
    } @else {
        @return calc(#{$value});
    }
}

/// Returns the padding of the body
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
@function body-padding($size: grid-s) {

    $padding: grid-setting($size, body-padding);

    @return $padding;
}

/// Returns the header height for a specific viewport
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
@function header-height($size: grid-s, $inverted: false) {

    $height: grid-setting($size, header-height);

    @if $inverted {
        @return $height * -1;
    } @else {
        @return $height;
    }
}

/// Returns the top padding for couples // desktop only
///
/// @group humans-machines
/// @param {string} $size [grid-s] - The grid size
@function couple-top-padding($size: decent, $indent: false, $inverted: false) {

    $padding: grid-setting($size, body-padding);


    @if $inverted {
        @return calc((#{$padding} * 2 + 7rem + 5vh) * -1); // logo plus padding plus some
    } @else {

        @if $indent {
            @return calc(#{$padding} * 2 + 7rem + 5vh + #{$v-space--l});
        } @else {
            @return calc(#{$padding} * 2 + 7rem + 5vh);
        }
    }
}

/* Goldkant typography */

// ========================================================= Body declarations
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: "Marr Sans", Arial, sans-serif;
    color: $text;
    line-height: $line-height--s;
    letter-spacing: $letter-spacing--s;
}

// ========================================================= Text-Mixins

// Text small
@mixin text--xs {
    font-size: $fontsize--xs;
    letter-spacing: $letter-spacing--xs;
    line-height: $line-height--s;

    @include respond-to(decent) {
        font-size: $fontsize--s;
        line-height: $line-height--s;
        letter-spacing: $letter-spacing--s;
    }
}

// Text
@mixin text--s {
    font-size: $fontsize--s;
    line-height: $line-height--s;
}

// Text big
@mixin text--m {
    font-size: $fontsize--m;
    line-height: $line-height--m;
}

// Head 2
@mixin text--l {
    font-size: $fontsize--l;
    line-height: $line-height--l;

    @include respond-to(decent) {
        font-size: $fontsize--l-d;
        line-height: $line-height--l-d;
        letter-spacing: $letter-spacing--l-d;
    }
}

// Head 1
@mixin text--xl {
    font-size: $fontsize--xl;
    line-height: $line-height--xl;

    @include respond-to(decent) {
        font-size: $fontsize--xl-d;
        line-height: $line-height--xl-d;
        letter-spacing: $letter-spacing--l-d;
    }
}

// Italic styles
@mixin text--italic {
    font-family: "Marr Sans Italic", Arial, sans-serif;
    font-style: italic;
}

// ========================================================= Text elements

a {
    color: inherit;
    text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}

p {
    @include text--m;

    p + & {
        margin-top: 1rem;
    }

    h2 + & {
        margin-top: 2rem;
    }

    .brand-listing + &,
    .listing + &,
    &.text-big + &:not(.text-big) {
        margin-top: 4rem;
    }
}

h1,
h2 {
    @include text--l;
}

h1 {
    .main-col & {
        &:not(:first-child) {
            margin-top: 8rem;
        }
    }
}

h3,
h4,
h5,
h6 {
    @include text--s;
}

h2,
h3 {
    .main-col & {
        @include text--s;

        &:not(:first-child) {
            margin-top: 4rem;
        }
    }
}


// ========================================================= Specific text styles

.text-xsmall {
    @include text--xs;
}

.text-small {
    @include text--s;
}

.text-medium {
    @include text--m;
}

.text-big {
    @include text--l;

    @include respond-to(grid-s) {
        br { // prevent awkward linebreaks on mobile
            display: none;
        }
    }
}

.text-link {
    @include text--s;
    @include text--italic;
    color: $text--highlight;
    display: block;

    p + &,
    h1 + &,
    h2 + &,
    h3 + & {
        margin-top: 2rem;
    }

    & + & {
        margin-top: 0.25rem;
    }

    .listing + & {
        margin-top: 4rem;
    }
}

.post-date {
    margin-top: 1rem;
}

.post-date + p {
    margin-top: 2rem;
}

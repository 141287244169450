/// Collection of useful mixins
/// @group Mixins
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

/// Mixin for input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder {
    ::placeholder {@content}
}

/// Mixin for nested input placeholder styling
///
/// @group humans-machines
/// @content
@mixin placeholder-nested {
    &::placeholder {@content}
}

/// Mixin for including mobile safe hover effects
///
/// @group humans-machines
/// @content
@mixin hover {
    .no-mobile.no-touch & {
        &:hover {
            @content;
        }
    }
}

/// Mixin for applying mobile specific css
///
/// @group humans-machines
/// @content
@mixin no-hover {
    .mobile &,
    .touch & {
        @content;
    }
}

/// Mixin for pseudo elements
///
/// @group humans-machines
/// @param {string} $display [inline-block] - How to display the pseudo element
/// @param {string} $pos     [absolute]     - Position of the pseudo element
/// @param {string} $content [empty]        - Initial content to set
@mixin pseudo($display: inline-block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

/// Mixin to truncate a text
///
/// @group humans-machines
/// @param {number} $truncation-boundary - The max width for truncate
@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/// Mixin to fade elements against background with a gradient
///
/// @group humans-machines
/// @param {number} $side - side of the cover element
/// @param {number} $width - width of the overlaying gradient
/// @param {number} $color - background-color of the overlaying gradient
@mixin fade-cover($side: bottom, $size: 1.6em, $color: $background--default) {
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;

        // gradient on the right - default
        @if $side == 'right' {
            background: linear-gradient(to right, rgba($color, 0), $color);
            top: 0;
            bottom: 0;
            width: $size;
            right: 0;

            // gradient on the left
        } @else if $side == 'left' {
            background: linear-gradient(to right, $color, rgba($color, 0));
            left: 0;
            top: 0;
            bottom: 0;
            width: $size;

            // gradient on the bottom
        } @else if $side == 'bottom' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            bottom: 0;
            height: $size;

            // gradient on the top
        } @else if $side == 'top' {
            background: linear-gradient(to top, $color, rgba($color, 0));
            left: 0;
            right: 0;
            top: $size * -1;
            height: $size;
        }
    }
}

/// Mixin to show cover behind menu
///
/// @group humans-machines
@mixin menu-cover($size: decent) {
    @supports (position: sticky) {
        position: relative;

        &:before {
            @include pseudo(block, sticky);
            top: -1px;
            height: header-height($size);
            background: $background--default;
            z-index: 1;
        }

        // remove cover from flow
        > :first-child {
            margin-top: header-height($size, true);
        }
    }
}

/// Mixin to show specific cover behind modal
///
/// @group humans-machines
@mixin modal-cover() {
    // specific modal cover
    &:after {
        @include pseudo();
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $background--fade;
        opacity: 0;
        cursor: pointer;
        visibility: hidden;
        transition: opacity $trans-time--nav $trans-func--nav,
        visibility 0s $trans-func--nav $trans-time--nav;
        z-index: map-get($z-index, active-cover);
    }

    &.is-active-cover {
        &:after {
            transition: opacity $trans-time--nav $trans-func--nav;
            opacity: 1;
            visibility: visible;
        }
    }

    &.is-active-cover--still {
        &:after {
            z-index: map-get($z-index, active-cover);
        }
    }
}

/// Mixin for a pseudo list like a navigation
///
/// @group humans-machines
@mixin pseudo-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

/// Mixin for a clearfix, since flexbox we might not need it anymore
///
/// @group humans-machines
@mixin clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

/// Mixin to maintain an aspect ratio of a container
///
/// @group humans-machines
/// @param {number} $width - The width ratio of the container
/// @param {number} $height - The height ratio of the container
@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

/// Mixin for fluid typography
///
/// @group humans-machines
/// @param {list} $properties - The properties to apply the calculation e.g. font-size, padding, ...
/// @param {number} $min-vw - The min viewport width in pixel
/// @param {number} $max-vw - The max viewport width in pixel
/// @param {number} $min-value - The min value to apply
/// @param {number} $max-value - The max value to apply
@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }

    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}


/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin transition-delay($delay: 0.1s, $global-delay: 0s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            transition-delay: $i * $delay - $delay + $global-delay;
        }
    }
}

/// Nice mixins to have a delay cascade
///
/// @group humans-machines
@mixin animation-delay($delay: 0.1s, $global-delay: 0s, $items: 20) {
    @for $i from 0 through $items {
        &:nth-child(#{$i}) {
            animation-delay: $i * $delay + $global-delay;
        }
    }
}

/// Nice mixins to have an underline on hover and on active links
///
/// @group humans-machines
@mixin underline-active {
    > a,
    > .btn:not(.btn--single-icon) {
        display: block;

        span {
            position: relative;

            &:before {
                @include pseudo();
                bottom: -0.2rem;
                left: 50%;
                transform: translateX(-50%) scaleX(0);
                height: 1px;
                width: 100%;
                opacity: 0;
                background: rgba($text--default, 0.8);
                transition: transform 0.15s $trans-func--default,
                opacity 0.15s $trans-func--default;
            }
        }
    }

    .no-mobile.no-touch &:hover,
    body:not(.has-secondary-menu) &.is-active,
    &.has-secondary {
        > a,
        > .btn:not(.btn--single-icon) {
            span {
                &:before {
                    // width: 100%;
                    transform: translateX(-50%) scaleX(1);
                    opacity: 1;
                    transition: transform 0.15s $trans-func--default 0.05s,
                    opacity 0.15s $trans-func--default 0.05s;
                }
            }
        }
    }

    .ie &,
    .edge & {
        span:before {
            transition: none !important;
        }
    }
}


/// Nice mixins to animate list items on hover
///
/* @group humans-machines
@mixin list-hover($offset: -0.1em) {
    a {
        .no-mobile.no-touch & {
            display: block;

            span {
                position: relative;

                &:before {
                    @include pseudo();
                    bottom: $offset;
                    left: 50%;
                    transform: translateX(-50%) scaleX(0);
                    height: 1px;
                    width: 100%;
                    opacity: 0;
                    background: rgba($text--default, 0.8);
                    transition: transform 0.15s $trans-func--default, opacity 0.15s $trans-func--default;
                }
            }
        }

        @include hover {
            span {
                &:before {
                    // width: 100%;
                    transform: translateX(-50%) scaleX(1);
                    opacity: 1;
                    transition: transform 0.15s $trans-func--default 0.05s, opacity 0.15s $trans-func--default 0.05s;
                }
            }
        }

        .ie &,
        .edge & {
            span:before {
                transition: none !important;
            }
        }
    }
}*/

/// Hie scrollbars on ios and other webkits
///
/// @group humans-machines
@mixin hide-scrollbars() {
    &::-webkit-scrollbar {
        height: 0;  /* remove scrollbar space */
        width: 0;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
        display: none;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
    }
}

/// Hide Elements Visually without loosing accessebility
/// Ref: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
///
/// @group humans-machines
@mixin hide {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px !important;
    padding: 0;
    border: 0;
}

/// Breakpoint Definitions
/// @group Breakpoints
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

// Mobile
$bp--small-e: 599px;

// Tablet Portrait
$bp--medium-s: 600px;
$bp--medium-e: 999px;

// Desktop / Table landscape
$bp--large-s: 1000px;
$bp--large-e: 1399px;

// Table landscape without desktop
$bp--large-m-s: 1000px;
$bp--large-m-e: 1259px;

// Large Desktop
$bp--extra-large-s: 1400px;

/// Mixin for breakpoints
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin breakpoint($name) {
    @if map-has-key($breakpoint-names, $name) {
        // Normalize name
        $name: map-get($breakpoint-names, $name);

        @if $name == s { // Mobile
            @media only screen and (max-width: $bp--small-e) {
                @content;
            }
        } @else if $name == m { // Portrait Tablet
            @media only screen and (min-width: $bp--medium-s) and (max-width: $bp--medium-e) {
                @content;
            }
        } @else if $name == l { // Desktop / Table landscape
            @media only screen and (min-width: $bp--large-s) {
                @content;
            }
        } @else if $name == lm { // Table landscape without desktop
            @media only screen and (min-width: $bp--large-m-s) and (max-width: $bp--large-m-e) {
                @content;
            }
        } @else if $name == xl { // Large Desktop
            @media only screen and (min-width: $bp--extra-large-s) {
                @content;
            }
        } @else if $name == decent { // m--xl // Tablet to Large Desktop --> used for everything not one-row-mobile
            @media only screen and (min-width: $bp--medium-s) {
                @content;
            }
        } @else if $name == port { // portrait
            @media only screen and (orientation: portrait) {
                @content;
            }
        }
    } @else {
        @if str-index($name, '--') > 0 {
            @if $name == s--m { // Mobile to Tablet
                @media only screen and (max-width: $bp--medium-e) {
                    @content;
                }
            } @else if $name == s--l { // Mobile to Desktop
                @media only screen and (max-width: $bp--large-e) {
                    @content;
                }
            } @else if $name == m--l { // Tablet to Desktop
                @media only screen and (min-width: $bp--medium-s) and (max-width: $bp--large-e) {
                    @content;
                }
            } @else if $name == m--xl { // Tablet to Large Desktop
                @media only screen and (min-width: $bp--medium-s) {
                    @content;
                }
            } @else if $name == l--xl { // Desktop to Large Desktop
                @media only screen and (min-width: $bp--large-s) {
                    @content;
                }
            }  @else {
                @warn 'Ooooops ... `#{$name}` breakpoint range is not defined!';
            }
        } @else {
            @warn 'Ooooops ... `#{$name}` breakpoint is not defined!';
        }
    }
}

/// Alias for the breakpoint mixin
///
/// @group humans-machines
/// @content
/// @param {string} $name - The name of the breakpoint
@mixin respond-to($name) {
    @include breakpoint($name) {
        @content;
    }
}

/// Brand Overview
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

.brand-listing {
    a {
        @include text--xl;
        display: block;
    }
}

.brand-images__img {
    visibility: hidden;
    opacity: 0;
    transition: opacity $trans-time--s $trans-func--default $trans-time--s,
                visibility 0s linear $trans-time--m;

    &.is-active {
        visibility: visible;
        opacity: 1;
        transition: opacity $trans-time--m $trans-func--default;
    }
}

.brand-images-title {
    transition: opacity $trans-time--l $trans-func--default 0.1s;

    &.has-active-child {
        opacity: 0;
        transition: opacity $trans-time--s $trans-func--default;
    }
}

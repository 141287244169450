/// Fontface Definitions
/// @group Fontface
/// @author Stefan Rueschenberg <Stefan@Humans-Machines.com>
/// @author Raffael Stueken <Raffael@Humans-Machines.com>
////

@font-face {
    font-family: 'Marr Sans';
    src: url('fonts/MarrSans-Regular-Web.woff') format('woff2'),
         url('fonts/MarrSans-Regular-Web.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Marr Sans Italic';
    src: url('fonts/MarrSans-RegularItalic-Web.woff') format('woff2'),
         url('fonts/MarrSans-RegularItalic-Web.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
